import FlowContentRendered from "./FlowContentRenderer";
import Button from "components/organisms/Button";
import { motion } from "framer-motion";
import { topToBottomSlideIn } from "../../utils/animations";
import { useTranslation } from "react-i18next";

interface StartFlowProps {
  nextProcessStep: any;
}

const StartFlow = (props: StartFlowProps) => {
  const { t } = useTranslation();
  const endIcon =
    "<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>" +
    "<path d='M4.99992 10.8249H13.3333L10.5916 13.5665C10.5135 13.644 10.4515 13.7362 10.4092 13.8377C10.3669 13.9393 10.3451 14.0482 10.3451 14.1582C10.3451 14.2682 10.3669 14.3771 10.4092 14.4787C10.4515 14.5802 10.5135 14.6724 10.5916 14.7499C10.7477 14.9051 10.9589 14.9922 11.1791 14.9922C11.3992 14.9922 11.6104 14.9051 11.7666 14.7499L15.3416 11.1665C15.6546 10.8554 15.8314 10.4328 15.8333 9.99152V9.99152C15.8292 9.5531 15.6525 9.13394 15.3416 8.82486L11.7666 5.24152C11.6889 5.16437 11.5968 5.10328 11.4954 5.06173C11.3941 5.02019 11.2856 4.999 11.1761 4.99939C11.0666 4.99978 10.9583 5.02173 10.8573 5.06399C10.7563 5.10625 10.6646 5.16799 10.5874 5.24569C10.5103 5.32339 10.4492 5.41552 10.4076 5.51683C10.3661 5.61814 10.3449 5.72664 10.3453 5.83613C10.3457 5.94563 10.3676 6.05398 10.4099 6.15499C10.4521 6.256 10.5139 6.3477 10.5916 6.42485L13.3333 9.15819H4.99992C4.7789 9.15819 4.56694 9.24599 4.41066 9.40227C4.25438 9.55855 4.16658 9.77051 4.16658 9.99152C4.16658 10.2125 4.25438 10.4245 4.41066 10.5808C4.56694 10.7371 4.7789 10.8249 4.99992 10.8249Z' fill='white'/>" +
    "</svg>";

  return (
    <motion.div
      initial="hidden"
      animate="enter"
      exit="exit"
      variants={topToBottomSlideIn}
      className="content d-flex"
    >
      <div
        className="flex-1 flow-container w-100 flow-start"
        style={{
          backgroundImage: `url(/images/background.svg)`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <FlowContentRendered>
          <div className="d-flex flex-column align-items-center justify-content-center h-100">
            <div className="flex-1 flow-start-image d-flex align-items-end">
              <img src="/images/start-ill.png" className="img-fluid" />
            </div>
            <div className="flex-1 flow-start-content">
              <div className="h-100 d-flex flex-column justify-content-around align-items-center">
                <div className="flow-start-txt">
                  <div className="start-title text-dark2">
                    {t("start-flow")}
                  </div>
                  <div className="start-description text-body pt-4">
                    {t("start-flow-descr")}
                  </div>
                </div>
                <div className="flow-start-action">
                  <Button
                    endIcon={endIcon}
                    variant="primary"
                    size="large"
                    className="mt-4"
                    onClick={props.nextProcessStep}
                  >
                    {t("general.start")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </FlowContentRendered>
      </div>
    </motion.div>
  );
};

export default StartFlow;
