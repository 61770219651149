import React from "react";
import Button from "components/organisms/Button";
import { useTranslation } from "react-i18next";

interface StepperActionsProps {
  actionConfirm?: string;
  actionDecline?: string;
  onActionDecline: any;
  onActionConfirm: any;
}

const StepperActions = (props: StepperActionsProps) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex flex-row justify-content-center">
      {props.actionDecline && (
        <Button
          variant="error"
          size="large"
          className="mt-4 al mr-3 ml-3"
          onClick={props.onActionDecline}
        >
          {t(props.actionDecline || 'general.continue')}
        </Button>
      )}
      <Button
        variant="primary"
        size="large"
        className="mt-4 al mr-3 ml-3"
        onClick={props.onActionConfirm}
      >
        {t(props.actionConfirm || 'general.continue')}
      </Button>
    </div>
  );
};

export default StepperActions;
