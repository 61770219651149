export const opacityTransition = {
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  };
  

  export const scaleTransition = {
      in: {
          scaleY: 0
      },
      out: {
          scaleY: 0
      },
      animate: {
        scaleY: 1,
        transition:{
          duration: 0.5
        }
      },
  }

  export const rightToLeftSlideIn = {
    in: {
      opacity: 0,
      x: "98vw"
    },
    out: {
      opacity: 1,
      x: 0,
      transition: {type: "spring"}
    },
    exit: {
      x: "-100vh",
      transition: {
         ease: "easeInOut"
      }
    }
  }

  export const leftToRightSlideIn = {
    hidden: { opacity: 0, x: -400, y: 0 },
    enter: { opacity: 1, x: 0, y: 0, 
      transition:{
        duration: 0.5
      } 
    },
    exit: { opacity: 0, x: 0, y: -200 },
  }

  export const topToBottomSlideIn = {
    hidden: { opacity: 0},
    enter: { opacity: 1, 
      transition:{
        duration: 0.5
      } 
    },
    exit: { opacity: 0 },
  }