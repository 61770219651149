import { useTranslation } from "react-i18next";

const LoginBanner = () => {
  const { t } = useTranslation();

  return (
    <div className="login-banner d-flex flex-column align-items-center w-50">
      <div
        className="login-banner-image"
      >
        <img src="/images/login-iphone.png" />
      </div>
      <div className="login-banner-description">
        <h1 className="text-dark">{t("auth.title")}</h1>
        <div className="login-description-text text-body">
          {t("auth.description")}
        </div>
      </div>
    </div>
  );
};

export default LoginBanner;
