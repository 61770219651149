import React, { forwardRef, useState } from "react";

type PopupProps = {
  id?: string;
  children?: React.ReactNode | React.ReactNode[];
  icon: string;
  description: React.ReactNode | React.ReactNode[] | string;
  headerText: string;
  closeHandler?: any;
};

function PopupWithRef(props: PopupProps, ref: any) {
  const { children, icon, headerText, description, closeHandler, ...rest } =
    props;

  return (
    <>
      <div className="popup-cover">
        <div className="popup-page d-flex justify-content-center align-items-center">
          <div className="popup-container">
            <div className="pop-up-content d-flex justify-content-center align-items-center flex-column">
              <div className="w-100 d-flex justify-content-end">
                {closeHandler && (
                  <svg
                    onClick={closeHandler}
                    className="close-popup-icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 6L6 18"
                      stroke="#353B50"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6 6L18 18"
                      stroke="#353B50"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </div>
              <img
                className="popup-incoming-icon"
                src={icon}
                alt="icon-image"
              />
              <h1
                className="m-top-075 text-dark font-weight-semi-bold header-popup-description"
                dangerouslySetInnerHTML={{ __html: headerText }}
              ></h1>
              <div className="m-top-075 font-weight-medium text-body popup-description-text">
                {typeof description === "string" ? (
                  <span
                    dangerouslySetInnerHTML={{ __html: description }}
                  ></span>
                ) : (
                  description
                )}
              </div>
              <div className="m-top-075 w-100">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const Popup = forwardRef(PopupWithRef);

export default Popup as typeof PopupWithRef;
