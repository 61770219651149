interface FlowContentRenderedProps {
  children: React.ReactNode | React.ReactNode[];
}
const FlowContentRendered = (props: FlowContentRenderedProps) => {
  return(
    <div className="d-flex flex-column step-container justify-content-around h-100">{props.children}</div>
  )
};

export default FlowContentRendered;
