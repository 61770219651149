import axios from "axios";

export const buybackInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BUYBACK_API_BASE_URL}`,
})

buybackInstance.interceptors.request.use(
    config => {
        // @ts-ignore
        let loggedToken = localStorage.getItem(process.env.REACT_APP_LOGIN_TOKEN_ID)
        if (loggedToken) {
            // @ts-ignore
            config.headers.Authorization = `${loggedToken}`;
        }
        return config;
    },
    error => {
        Promise.reject(error);
    }
  );
