import i18next from 'i18next';
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from 'react-i18next';
import translationEN from './en/translation.json';
import translationRO from './ro/translation.json';
import translationBG from './bg/translation.json';
import translationHU from './hu/translation.json';

i18next.use(initReactI18next).use(detector).init({
  fallbackLng: 'ro', // if you're using a language detector, do not define the lng option
  debug: true,
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: translationEN,
    },
    ro:{
      translation: translationRO,
    },
    bg:{
      translation: translationBG,
    },
    hu:{
      translation: translationHU,
    }
  },
});