import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { loginRedirect } from "hoc/LoginRedirect";
import { motion } from "framer-motion";
import { topToBottomSlideIn } from "utils/animations";
import { DatePicker, Space } from "antd";
import { buybackAPI } from "api/buybackAPI";
import { buildBlob } from "utils/api";

import Menu from "components/Menu";
import Navbar from "components/Navbar";
import Button from "components/organisms/Button";
import { useTranslation } from "react-i18next";

const { RangePicker } = DatePicker;

const dateFormat = "DD-MM-YYYY";

const RaportStoc = () => {
  const { t } = useTranslation();

  const [waiting, setWaiting] = useState<boolean>(false);
  const [startTime, setStartTime] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");
  const [errMessage, setErrMessage] = useState<string>("");

  const onChange = (date: any, dateString: any) => {
    setErrMessage("");

    const sTime = dateString[0];
    const eTime = dateString[1];

    setStartTime(sTime);
    setEndTime(eTime);
  };

  const onSubmit = () => {
    buybackAPI
      .downloadStock(startTime, endTime)
      .then((res) => {
        if (res.data.byteLength > 100) {
          buildBlob(res.data, `${t("stoc-report")}.xlsx`);
        } else {
          setErrMessage(t("no-records"));
        }
      })
      .catch((err) => {
        setErrMessage(t("error.download"));
      });
  };



  return (
    <>
      <Menu></Menu>
      <div className="page-content">
        <Navbar
          title=""
          subtitle={t("stoc-report")}
          showCard={true}
          showGoBackButton={false}
          showCancelButton={false}
        ></Navbar>

        <motion.div
          initial="hidden"
          animate="enter"
          exit="exit"
          variants={topToBottomSlideIn}
          className="content d-flex"
        >
          <div className="flex-1 flow-container w-100 m-5">
            <div className="step-content-card w-60 mx-auto">
              <p className="font-size-20 font-weight-bold text-align-center mb-4">
                {t("download-stoc-report")}
              </p>

              <div>
                <p className="font-size-16 font-weight-regular text-align-start mb-2">
                  {t("general.interval")}
                </p>
                <RangePicker
                  className="w-100"
                  format={dateFormat}
                  onChange={onChange}
                />
                {errMessage && (
                  <p className="font-size-14 font-weight-regular text-red text-align-start mt-2">
                    {errMessage}
                  </p>
                )}
                <Button
                  variant="primary"
                  size="small"
                  className="mx-auto mt-4"
                  wait={waiting}
                  type="submit"
                  onClick={onSubmit}
                >
                  {t("download-report")}
                </Button>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default loginRedirect(RaportStoc);
