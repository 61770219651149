import { useState } from "react";
import { useSelector } from "react-redux";

import Popup from "./organisms/Popup";
import Button from "./organisms/Button";

import generatePhoneSheet from "./PhoneSheet";
import { useTranslation } from "react-i18next";

type PopupPhoneSheetProps = {
  todayDate: string;
  closeSheetPopup: any;
  openSuccessPopup: any;
};

const PopupPhoneSheet = (props: PopupPhoneSheetProps) => {
  const { t } = useTranslation();
  const { todayDate, closeSheetPopup, openSuccessPopup } = props;

  const [isPrinted, setIsPrinted] = useState<boolean>(false);

  const { sellOrderID, phoneDetails, IMEI, defects, repairs } = useSelector(
    (state: any) => state.phone
  );

  return (
    <Popup
      icon={"/images/folder.png"}
      headerText={t("identification-sheet")}
      description={t("identification-sheet-descr")}
    >
      <Button
        variant="outlined"
        size="large"
        className="w-100 mt-5"
        onClick={() => {
          generatePhoneSheet(
            sellOrderID,
            `${phoneDetails[0].value}, ${phoneDetails[1].value}, ${phoneDetails[2].value}`,
            IMEI,
            defects.concat(repairs).join(", "),
            t
          );
          setIsPrinted(true);
        }}
      >
        {isPrinted ? t("download-ready") : t("download-sheet")}
      </Button>
      <Button
        variant={isPrinted ? "primary" : "disabled"}
        size="large"
        className="w-100 mt-3"
        disabled={!isPrinted}
        onClick={() => {
          closeSheetPopup();
          openSuccessPopup();
        }}
      >
       {t("confirm-print")}
      </Button>
    </Popup>
  );
};

export default PopupPhoneSheet;
