import LoginForm from "./loginForm";
import LoginBanner from "./loginBanner";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import DropdownLanguage from "components/LanguageSelector";

const Login = () => {
  return (
    <div className="login-page d-flex justify-content-center align-items-center">
      <div className="login-container d-flex flex-row align-content-around">
        <LoginBanner />
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA || ''}>
          <LoginForm />
        </GoogleReCaptchaProvider>
        <div className="dropdown-selector">
          <DropdownLanguage />
        </div>
      </div>
    </div>
  );
};

export default Login;
