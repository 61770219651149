import { useTranslation } from "react-i18next";

interface HeaderFlowProps {
  title: string;
  subtitle: string;
  iconSize?: "sm" | "lg";
}

const HeaderFlow = (props: HeaderFlowProps) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex flex-column align-items-center mb-5">
      <div
        className={`d-flex align-items-center ${
          props.iconSize
            ? props.iconSize === "sm"
              ? "flex-row"
              : "flex-column"
            : "flex-row"
        }`}
      >
        <div
          className={`d-flex flex-1 flow-header-image-${
            props.iconSize ? props.iconSize : "sm"
          }`}
        >
          <img
            src={`/images/robo-${props.iconSize ? props.iconSize : "sm"}.png`}
            className={`img-fluid ${props.iconSize ? "mb-3" : "mr-2"}`}
          />
        </div>
        <div className="font-size-16 text-body mb-1">{t(props.subtitle)}</div>
      </div>
      <h2 className="header-title text-dark2 pt-2">{t(props.title)}</h2>
    </div>
  );
};

export default HeaderFlow;
