import axios from "axios";

export const instance = axios.create({
    baseURL: process.env.REACT_APP_LOGIN_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        Accept: '/',
    },
    withCredentials: true
})

instance.interceptors.request.use(
    config => {
        // @ts-ignore
        let loggedToken = localStorage.getItem(process.env.REACT_APP_LOGIN_TOKEN_ID)
        if (loggedToken) {
            // @ts-ignore
            config.headers.Authorization = `${loggedToken}`;
        }
        return config;
    },
    error => {
        Promise.reject(error);
    }
  );

export const authAPI = {
    login(email: string, password: string, recaptcha: string) {
        return instance.post<LoginResponseType>(`/login`, {
            email,
            password,
            recaptcha
        })
    },
    getSession() {
        return instance.get<SessionResponseType>(`/session`)
    }
}

type LoginResponseType = {
    data: {
        jwt: string
    }
}

type SessionResponseType = {
    data: {
        id: string,
        email: string,
        jwt_token_secret: string,
        name: string,
        activated_at: string,
        deactivated_at: string | null,
        created_at: string,
        role: string
    }
}
