import React, { forwardRef, useEffect } from "react";
import { Table, Pagination, Select } from "antd";
import { useTranslation } from "react-i18next";
const { Option } = Select;

type TableProps = {
  id?: string;
  columns?: any[];
  data?: any[];
  selectionType?: any;
  rowSelection?: any;
  rowKey?: string;
  currentPage: number;
  total: number;
  pageSize: number;
  pages: number[];
  handleElementsPerPage?: any;
  handlePage?: any;
  handleSearch?: any;
};

function TableWithRef(props: TableProps, ref: any) {
  const { t } = useTranslation();

  const {
    id,
    columns,
    data,
    selectionType = "checkbox",
    rowSelection,
    rowKey,
    currentPage,
    total,
    pageSize,
    pages,
    handleElementsPerPage,
    handlePage,
    handleSearch,
  } = props;

  return (
    <div className="table-wrapper">
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey={rowKey}
        rowSelection={
          rowSelection && {
            type: selectionType,
            ...rowSelection,
          }
        }
      />
      <div style={{ position: "relative" }}>
        <Pagination
          defaultCurrent={1}
          current={currentPage}
          total={total}
          onChange={handlePage}
          pageSize={pageSize}
        />
        <div className="size-changer-wrapper">
          <p className="size-changer-text">
            {t("show-results", { total: pageSize > total ? `${total}/${total} ` : `${pageSize}/${total} ` })}
          </p>
          <Select
            className="size-changer"
            defaultValue="10"
            style={{ width: 60 }}
            onChange={handleElementsPerPage}
          >
            <Option value="10">10</Option>
            <Option value="20">20</Option>
            <Option value="30">30</Option>
            <Option value="40">40</Option>
          </Select>
        </div>
        <div className="quick-jumper-wrapper">
          <p className="quick-jumper-text"> {t("choose-page")}</p>
          <Select
            className="quick-jumper"
            defaultValue={currentPage}
            value={currentPage}
            style={{ width: 50 }}
            onChange={handlePage}
          >
            {pages &&
              pages.map((page, index) => {
                return (
                  <Option key={index} value={page}>
                    {page}
                  </Option>
                );
              })}
          </Select>
        </div>
      </div>
    </div>
  );
}

const TableComponent = forwardRef(TableWithRef);

export default TableComponent as typeof TableWithRef;
