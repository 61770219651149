import { DefaultThunkType, InferActionTypes } from "../Store"
import { authAPI } from '../../api/authAPI'
import { buybackAPI } from "api/buybackAPI"
import { setLocationIdAction, setLocationNameAction, setLocationsAction } from "redux/actions/LocationAction"

const setError = 'flip-buyback/login/SET_ERROR'
const setIsAuth = 'flip-buyback/login/SET_IS_AUTH'
const setRole = 'flip-buyback/login/SET_ROLE'
const setEmail = 'flip-buyback/login/SET_EMAIL'
const setSupplier = 'flip-buyback/login/SET_SUPPLIER'

let initialState = {
  isAuth: false,
  error: {
    message: '',
  },
  role: "",
  email: "",
  supplier: ""
}

const authReducer = (
  state = initialState,
  action: ActionsType
): InitialStateType => {
  switch (action.type) {
    case setIsAuth: {
      return { ...state, isAuth: action.isAuthState }
    }

    case setError: {
      return {
        ...state,
        error: {
          ...state.error,
          message: action.error,
        },
      }
    }
    case setRole: {
      return {...state, role: action.role }
    }
    case setEmail: {
      return {...state, email: action.email}
    }
    case setSupplier: {
      return {...state, supplier: action.supplier}
    }
    default:
      return state
  }
}

export const loginActions = {
  setIsAuth: (isAuthState: boolean) =>
  ({
    type: setIsAuth,
    isAuthState,
  } as const),
  setErrorMessage: (error: string) =>
  ({
    type: setError,
    error,
  } as const),
  setRole: (role: string) =>
  ({
    type: setRole,
    role,
  } as const),
  setEmail: (email: string) =>
  ({
    type: setEmail,
    email,
  } as const),
  setSupplier: (supplier: string) =>
  ({
    type: setSupplier,
    supplier,
  } as const),
}

export const userLogin = (
  email: string,
  password: string,
  recaptcha: string,
  t: any
): ThunkType => {
  return async (dispatch: any) => {
    try {

      let response = await authAPI.login(email, password, recaptcha)
      
      dispatch(loginActions.setIsAuth(true))

      if (process.env.REACT_APP_LOGIN_TOKEN_ID) {
        localStorage.setItem(
          process.env.REACT_APP_LOGIN_TOKEN_ID,
          response.data.data.jwt
        )

        try{
          const sessionResponse = await buybackAPI.session();
          const { role, email, locations, supplier } = sessionResponse.data.data;
          if (locations) {
            const firstLocation = locations[0];
            const firstLocationKey = Object.keys(firstLocation)[0];
            // @ts-ignore
            const firstLocationPair = firstLocation[Object.keys(firstLocation)[0]];

            dispatch(setLocationNameAction(firstLocationKey))
            dispatch(setLocationIdAction(firstLocationPair))
          }
          dispatch(setRoleAction(role));
          dispatch(setEmailAction(email));
          dispatch(setSupplierAction(supplier));
          dispatch(setLocationsAction(locations));
        } catch (err){
        }
      }
    } catch (err: any) {
      if (err && [400, 401, 403].includes(err.response.status)) {
        dispatch(
          loginActions.setErrorMessage(
            t(`error.${err.response.data.errorCode}`) ||
            'Credentialele furnizate sunt incorecte'
          )
        )
      } else {
        dispatch(
          loginActions.setErrorMessage(
            t(`error.1500`)
          )
        )
      }
    }
  }
}

export const userLogout = (): ThunkType => {
  return async (dispatch: any) => {
    dispatch(loginActions.setIsAuth(false))
    dispatch(setLocationNameAction(""))
    dispatch(setRoleAction(""))
    localStorage.clear()
    dispatch(loginActions.setErrorMessage(''))
  }
}

export const setRoleAction = (role: string) : ThunkType => {
  return async (dispatch: any) => {
    dispatch(loginActions.setRole(role))
  }
}

export const setEmailAction = (email: string) : ThunkType => {
  return async (dispatch: any) => {
    dispatch(loginActions.setEmail(email))
  }
}

export const setSupplierAction = (supplier: string) : ThunkType => {
  return async (dispatch: any) => {
    dispatch(loginActions.setSupplier(supplier))
  }
}

export default authReducer

type InitialStateType = typeof initialState
type ActionsType = InferActionTypes<typeof loginActions>
type ThunkType = DefaultThunkType<ActionsType>
