import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { motion } from "framer-motion";

import Menu from "components/Menu";
import Navbar from "components/Navbar";
import Tag from "components/organisms/Tag";
import Search from "components/organisms/Search";
import TableComponent from "components/organisms/Table";

import { downloadIcon } from "icons/Icons";
import { buybackAPI } from "api/buybackAPI";

import { loginRedirect } from "hoc/LoginRedirect";

import { downloadFile } from "utils/api";
import { buildOptionString } from "utils/functions";
import { topToBottomSlideIn } from "utils/animations";
import { useTranslation } from "react-i18next";

const TransportSheets = () => {
  const { locationId } = useSelector((state: any) => state.location);

  const {t} = useTranslation();

  const [data, setData] = useState<any[]>([]);
  const [stateData, setStateData] = useState<any[]>([]);
  const [elsPerPage, setElsPerPage] = useState(10);
  const [counter, setCounter] = useState(11);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState<number[]>([]);
  const [selectOptions, setSelectOptions] = useState<string[]>([]);
  const [searchBy, setSearchBy] = useState<any>("name");

  enum ColumnsEnum {
    name = "Magazin",
    transport_document_id = "Fisa transport",
    shipment_date = "Data trimitere",
    count = "Numar telefoane",
    tracking_number = "AWB",
    shipment_status = "Status",
  }

  useEffect(() => {
    getTransportSheetsFromAPI();
    buildSelectOptions();
  }, []);

  useEffect(() => {
    setCounter(data.length);
  }, [data]);

  useEffect(() => {
    handlePages();
    onChange(currentPage);
  }, [counter, elsPerPage]);

  const getTransportSheetsFromAPI = async () => {
    try {
      const response = await buybackAPI.buybackShipped(locationId);
      const { shipments } = response.data;
      setData(shipments);
      setStateData(shipments);
    } catch (error) {}
  };

  const buildSelectOptions = () => {
    let keys: string[] = [];

    columns.map((column) => {
      if (column.key !== "operation") {
        keys.push(buildOptionString(column.title));
      }
    });

    setSelectOptions(keys);
  };

  const handlePages = () => {
    let nrOfPages = Math.ceil(counter / elsPerPage);
    let pagesArray: number[] = [];
    for (let i = 1; i <= nrOfPages; i++) {
      pagesArray.push(i);
    }
    setPages(pagesArray);
  };

  const onChange = (pageNumber: string | number) => {
    setStateData(
      data.slice(
        (Number(pageNumber) - 1) * elsPerPage,
        Number(pageNumber) * elsPerPage
      )
    );

    setCurrentPage(Number(pageNumber));
  };

  const handleElsPerPage = (e: any) => {
    if (e < counter) {
      setElsPerPage(e);
    } else {
      setElsPerPage(counter);
    }
  };

  const handleSearch = (e: any) => {
    const filteredData = data.filter((el: any) => {
      if (el[searchBy]) {
        if (
          el[searchBy]
            .toString()
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          return el;
        }
      }
    });
    setStateData(
      filteredData.slice(
        (Number(currentPage) - 1) * elsPerPage,
        Number(currentPage) * elsPerPage
      )
    );
  };

  const handleSelect = (val: any) => {
    const valKey =
      Object.keys(ColumnsEnum)[
        Object.values(ColumnsEnum).indexOf(val as unknown as ColumnsEnum)
      ];
    valKey && setSearchBy(valKey);
  };

  const firstLetterToLowerCase = (val: string) => {
    if (val) {
      val = val.replace(" ", "_");
      val = val.charAt(0).toLowerCase() + val.slice(1);
      return val;
    }
  };

  const columns = [
    {
      title: t("general.marketplace"),
      dataIndex: "name",
      key: "name",
      width: 150,
    },
    {
      title: t("transport-sheet"),
      dataIndex: "transport_document_id",
      key: "transport_document_id",
      width: 150,
    },
    {
      title: t("date-sent"),
      dataIndex: "shipment_date",
      key: "shipment_date",
      width: 150,
      render: (shipment_date: string) => (
        <p>
          {new Date(shipment_date).getDate() +
            "/" +
            (new Date(shipment_date).getMonth() + 1) +
            "/" +
            new Date(shipment_date).getFullYear()}
        </p>
      ),
    },
    {
      title: t("count-phones"),
      dataIndex: "count",
      key: "count",
      width: 150,
    },
    {
      title:  t("AWB"),
      dataIndex: "tracking_number",
      key: "tracking_number",
      width: 150,
      render: (text: any, record: any, index: any) => (
        <p
          className="text-underline cursor-pointer"
          onClick={() => downloadFile(`${record.tracking_number}.pdf`, "awb")}
        >
          {record.tracking_number}
        </p>
      ),
    },
    {
      title: t("status"),
      key: "status",
      dataIndex: "shipment_status",
      width: 150,
      render: (tag: any) => {
        let variant = "";
        switch (tag) {
          case "AWB_CREAT":
            variant = "yellow";
            break;
          case "IN_CURS_DE_LIVRARE":
            variant = "blue";
            break;
          case "LIVRAT":
            variant = "green";
            break;
          default:
            break;
        }
        return <Tag variant={variant} status={t(tag)} />;
      },
    },
    {
      title:t("download-sheet"),
      key: "operation",
      width: 200,
      render: (text: any, record: any, index: any) => (
        <div
          className="d-flex align-items-center"
          onClick={(e) => {
            downloadFile(`${record.filename}`, "trdoc");
          }}
        >
          <span
            className="icon start"
            dangerouslySetInnerHTML={{ __html: downloadIcon }}
          ></span>

          <a className="ml-2">{t("download-sheet")}</a>
        </div>
      ),
    },
  ];

  return (
    <>
      <Menu></Menu>
      <div className="page-content">
        <Navbar
          title={t("in-house")}
          subtitle={t("menu.transport")}
          showCard={true}
          showGoBackButton={false}
          showCancelButton={false}
        ></Navbar>
        <motion.div
          initial="hidden"
          animate="enter"
          exit="exit"
          variants={topToBottomSlideIn}
          className="content d-flex"
        >
          <div className="flex-1 flow-container w-100 m-5">
            <Search
              onChange={handleSearch}
              handleSelect={handleSelect}
              selectOptions={selectOptions}
              selectDefaultValue="Magazin"
            />

            <TableComponent
              pages={pages}
              data={stateData}
              columns={columns}
              total={data.length}
              pageSize={elsPerPage}
              handlePage={onChange}
              currentPage={currentPage}
              rowKey="transport_document_id"
              handleElementsPerPage={handleElsPerPage}
            />
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default loginRedirect(TransportSheets);
