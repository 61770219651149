import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { motion } from "framer-motion";

import Menu from "components/Menu";
import Navbar from "components/Navbar";
import Toast from "components/organisms/Toast";
import Popup from "components/organisms/Popup";
import Search from "components/organisms/Search";
import Button from "components/organisms/Button";
import TableComponent from "components/organisms/Table";

import { buybackAPI } from "api/buybackAPI";
import { loginRedirect } from "hoc/LoginRedirect";
import { topToBottomSlideIn } from "utils/animations";

import { buildOptionString } from "../utils/functions";
import { downloadFile } from "utils/api";
import { roleSuperAdmin } from "utils/constants";
import { useTranslation } from "react-i18next";

const SendPhones = () => {
  const { t } = useTranslation();
  const { locationId } = useSelector((state: any) => state.location);
  const { role } = useSelector((state: any) => state.login);
  const [data, setData] = useState<any[]>([]);
  const [stateData, setStateData] = useState<any[]>([]);
  const [elsPerPage, setElsPerPage] = useState(10);
  const [counter, setCounter] = useState(11);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState<number[]>([]);
  const [selectOptions, setSelectOptions] = useState<string[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [searchBy, setSearchBy] = useState<string>("id");
  const [toasts, setToasts] = useState<any[]>([]);
  const [brandsCount, setBrandsCount] = useState([]);
  const [transportSheet, setTransportSheet] = useState<any>();
  const [awbPopup, setAwbPopup] = useState<boolean>(false);
  const [waiting, setWaiting] = useState<boolean>(false);

  let listEl = useRef<HTMLDivElement>(null);

  enum ColumnsEnum {
    id = "ID Flip",
    name = "Model",
    storage = "Memorie",
    order_date = "Data receptie",
    imei_code = "IMEI",
    defects = "Defecte",
  }

  useEffect(() => {
    buildSelectOptions();
  }, []);

  useEffect(() => {
    getPhonesFromAPI();
  }, [locationId]);

  useEffect(() => {
    setCounter(data.length);
  }, [data]);

  useEffect(() => {
    handlePages();
    onChange(currentPage);
  }, [counter, elsPerPage]);

  const getPhonesFromAPI = async () => {
    try {
      const response = await buybackAPI.buybackInventory(locationId);
      const { brandsCount, inventory } = response.data.data;
      setData(inventory);
      setStateData(inventory);
      setBrandsCount(brandsCount);
    } catch (error) { }
  };

  const buildSelectOptions = () => {
    let keys: string[] = [];

    columns.map((column) => {
      keys.push(buildOptionString(column.title));
    });

    setSelectOptions(keys);
  };

  const handlePages = () => {
    let nrOfPages = Math.ceil(counter / elsPerPage);
    let pagesArray: number[] = [];
    for (let i = 1; i <= nrOfPages; i++) {
      pagesArray.push(i);
    }
    setPages(pagesArray);
  };

  const onChange = (pageNumber: string | number) => {
    setStateData(
      data.slice(
        (Number(pageNumber) - 1) * elsPerPage,
        Number(pageNumber) * elsPerPage
      )
    );

    setCurrentPage(Number(pageNumber));
  };

  const handleElsPerPage = (e: any) => {
    if (e < counter) {
      setElsPerPage(e);
    } else {
      setElsPerPage(counter);
    }
  };

  const handleSearch = (e: any) => {
    const filteredData = data.filter((el: any) => {
      if (el[searchBy]) {
        if (
          el[searchBy]
            .toString()
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          return el;
        }
      }
    });
    setStateData(
      filteredData.slice(
        (Number(currentPage) - 1) * elsPerPage,
        Number(currentPage) * elsPerPage
      )
    );
  };

  const handleSelect = (val: string) => {
    const valKey =
      Object.keys(ColumnsEnum)[
      Object.values(ColumnsEnum).indexOf(val as unknown as ColumnsEnum)
      ];
    valKey && setSearchBy(valKey);
  };

  const firstLetterToLowerCase = (val: string) => {
    if (val) {
      val = val.replace(" ", "_");
      val = val.charAt(0).toLowerCase() + val.slice(1);
      return val;
    }
  };

  const addToast = () => {
    let toastsCopy = [...toasts];
    toastsCopy.push({
      id: toastsCopy[toastsCopy.length - 1]
        ? toastsCopy[toastsCopy.length - 1].id + 1
        : 1,
      icon: "/images/folder2.png",
      title: t("generate-awb"),
      subtitle: t("not-opened-download"),
      link: t("general.here"),
    });
    setToasts(toastsCopy);
  };

  const deleteToast = (id: any) => {
    let toastsCopy = [...toasts];
    toastsCopy.forEach((toast) => {
      if (toast.id === id) {
        let index = toastsCopy.indexOf(toast);
        toastsCopy.splice(index, 1);
      }
    });
    setToasts(toastsCopy);
  };

  const generateTransportSheet = async () => {
    try {
      const response = await buybackAPI.buybackAddShipped(
        locationId,
        selectedRows,
        ""
      );
      setTransportSheet(response.data);
      downloadFile(undefined, undefined, response.data);
      addToast();
      setSelectedRows([]);
      getPhonesFromAPI();
    } catch (error) {
      setWaiting(false);
    }
  };

  const scrollToBottom = () => {
    if (listEl.current !== null) {
      listEl.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [toasts]);

  const columns = [
    {
      title: t("general.ID"),
      dataIndex: "id",
      key: "id",
      width: 150,
    },
    {
      title: t("general.model"),
      dataIndex: "name",
      key: "name",
      width: 150,
    },
    {
      title: t("general.memory"),
      dataIndex: "storage",
      key: "storage",
      width: 150,
    },
    {
      title: t("general.IMEI"),
      dataIndex: "imei_code",
      key: "imei_code",
      width: 150,
    },
    {
      title: t("general.defects"),
      dataIndex: "defects",
      key: "defects",
      width: 150,
    },
    {
      title: t("reception-date"),
      dataIndex: "order_date",
      key: "order_date",
      width: 150,
      render: (order_date: string) => (
        <p>
          {new Date(order_date).getDate() +
            "/" +
            (new Date(order_date).getMonth() + 1) +
            "/" +
            new Date(order_date).getFullYear()}
        </p>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRowsTable: any) => {
      setSelectedRows(selectedRowKeys);
    },
    getCheckboxProps: (record: any) => ({
      name: record.name,
    }),
  };
  return (
    <>
      <Menu></Menu>
      <div className="page-content">
        <Navbar
          title={t("in-house")}
          subtitle={t("menu.send-phones")}
          showCard={true}
          showGoBackButton={false}
          showCancelButton={false}
        ></Navbar>
        <motion.div
          initial="hidden"
          animate="enter"
          exit="exit"
          variants={topToBottomSlideIn}
          className="content d-flex"
        >
          <div className="flex-1 flow-container w-100 m-5">
            <Search
              brandsCount={brandsCount}
              selectDefaultValue="ID Flip"
              selectOptions={selectOptions}
              onChange={handleSearch}
              handleSelect={handleSelect}
            >
              {role !== roleSuperAdmin &&
                <Button
                  className="ml-4"
                  size="small"
                  variant={selectedRows.length === 0 ? "disabled" : "primary"}
                  disabled={selectedRows.length === 0 ? true : false}
                  onClick={() => setAwbPopup(true)}
                >
                  {t("generate-transport-sheet")}
                </Button>
              }
            </Search>

            <TableComponent
              rowKey="id"
              pages={pages}
              data={stateData}
              columns={columns}
              total={data.length}
              pageSize={elsPerPage}
              handlePage={onChange}
              currentPage={currentPage}
              rowSelection={role !== roleSuperAdmin && rowSelection}
              handleElementsPerPage={handleElsPerPage}
            />
          </div>
        </motion.div>
      </div>
      {/* s */}
      <div className="toast-container">
        <div className="toast-wrapper">
          {toasts?.reverse().map((toast: any) => (
            <motion.div
              key={toast.id}
              initial="hidden"
              animate="enter"
              exit="exit"
              className="item"
              variants={topToBottomSlideIn}
            >
              <Toast
                id={toast.id}
                icon={toast.icon}
                title={toast.title}
                subtitle={toast.subtitle}
                link={toast.link}
                onClickProp={deleteToast}
                onClickLinkProp={() =>
                  downloadFile(undefined, undefined, transportSheet)
                }
              />
            </motion.div>
          ))}
        </div>
        <div ref={listEl}></div>
      </div>

      {awbPopup && (
        <Popup
          icon="/images/folder2.png"
          headerText={t("generate-awb")}
          description={t("confirm-generate-awb", {
            products: selectedRows.length === 1
              ? `${selectedRows.length} ${t('product')}`
              : `${selectedRows.length} ${t('products')}`
          })}
        >
          <div className="d-flex justify-content-between mt-5">
            <Button
              variant="error"
              size="large"
              className="flex-48"
              onClick={() => setAwbPopup(false)}
            >
              {t("general.no")}
            </Button>
            <Button
              variant="primary"
              size="large"
              className="flex-48"
              onClick={() => {
                generateTransportSheet();
                setAwbPopup(false);
              }}
            >
              {t("general.yes")}
            </Button>
          </div>
        </Popup>
      )}
    </>
  );
};
export default loginRedirect(SendPhones);
