import React, { forwardRef, useEffect, useState, useRef } from "react";
import Button from "./Button";
import Input from "components/organisms/Input";
import { useDispatch, useSelector } from "react-redux";
import {
  setRepairsAction,
  setBuybackOfferAction,
  setUncheckedRepairsActions,
} from "redux/actions/PhoneDetailsAction";

import { buybackAPI } from "../../api/buybackAPI";
import { useTranslation } from "react-i18next";

interface ISelectorProps {
  data: any;
  displayKey: string;
  confirmButtonMessage: string;
  onConfirmSelection: any;
  setShowSelector: any;
}

let returnArray: any[] = [];

function Selector(props: ISelectorProps) {
  const { t } = useTranslation();
  
  const searchIcon =
    "<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>" +
    "<g opacity='0.5'>" +
    "<path d='M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z' stroke='#8892A4' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/>" +
    "<path d='M13.9996 14L11.0996 11.1' stroke='#8892A4' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></g></svg>";

  const { setShowSelector } = props;

  let ref = useRef(null);

  const dispatch = useDispatch();

  const { buybackID } = useSelector((state: any) => state.phone);
  const repairsRedux = useSelector((state: any) => state.phone.repairs);

  const [data, setData] = React.useState<any[]>(props.data);
  const [filterData, setFilterData] = React.useState<any[]>([]);
  const [repairs, setRepairs] = React.useState<any[]>(repairsRedux);
  const [countCheckedItems, setCountCheckedItems] = React.useState<number>(0);

  useEffect(() => {
    setFilterData(data);
  }, []);

  useEffect(() => {
    initialCheck();
  });

  const handleSearch = (inputText: any) => {
    const filteredData = data.filter((el: any) => {
      if (fuzzySearch(el, inputText)) {
        return el;
      }
    });
    setFilterData(filteredData);
  };

  const fuzzySearch = (element: string, inputString: string) => {
    var element = element.toLowerCase(),
      i = 0,
      n = -1,
      l;
    inputString = inputString.toLowerCase();
    for (; (l = inputString[i++]); )
      if (!~(n = element.indexOf(l, n + 1))) return false;
    return true;
  };

  const initialCheck = () => {
    let repairsCopy = [...repairs];
    repairsCopy.map((repair: any) => {
      let repairCheckbox = document.getElementById(repair);
      repairCheckbox && ((repairCheckbox as HTMLInputElement).checked = true);
    });
  };

  const onCheck = (item: any, inputValue: boolean) => {
    let repairsCopy = [...repairs];

    if (inputValue) {
      repairsCopy.push(item);

      setRepairs(repairsCopy);
      setCountCheckedItems((countCheckedItems) => countCheckedItems + 1);
    } else {
      let indexOf = repairsCopy.indexOf(item);
      repairsCopy.splice(indexOf, 1);

      setRepairs(repairsCopy);
      setCountCheckedItems((countCheckedItems) => countCheckedItems - 1);
    }
  };

  const confirmSelection = () => {
    dispatch(setRepairsAction(repairs));
    setShowSelector(false);
  };

  return (
    <div className="d-flex flex-column p-3 selector-container">
      <div className="d-flex flex-row justify-content-between pb-3">
        <div className="header-title text-dark2 text-align-start">  {t("general.view")}</div>
        <div className="text-body">
          {countCheckedItems}/{props.data.length}
        </div>
      </div>
      <Input
        className="relative"
        type="text"
        size="medium"
        startIcon={searchIcon}
        classNameStartIcon="pl-5"
        placeholder={t("general.search-defect")}
        onChange={(e: any) => handleSearch(e.target.value)}
      />
      <div className="d-flex flex-column pb-3 pt-3 ml-3 defects-list-container overflow-y-auto">
        {filterData.map((item, index: number) => (
          <div key={index} className="pb-3">
            <Input
              id={item}
              ref={ref}
              type="checkbox"
              variant="checkbox"
              checked={item.checked}
              classNameContainer="d-flex align-items-center"
              className="d-flex order-1 mr-4 relative"
              startLabel={t(`repairs.${item}`)}
              classNameStartLabel="text-dark2 float-left"
              onChange={(e: any) => onCheck(item, e.target.checked)}
            />
          </div>
        ))}
      </div>
      <Button
        variant="outlined"
        size="large"
        onClick={() => confirmSelection()}
      >
        {props.confirmButtonMessage}
      </Button>
    </div>
  );
}

export default Selector;
