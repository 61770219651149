import React, { ComponentType } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { AppStateType } from "../redux/Store";

let mapStateToPropsForRedirect = (state: AppStateType) =>
  ({
    isAuth: state.login.isAuth,
  } as MapPropsType);

type MapPropsType = {
  isAuth: boolean;
};
type DispatchPropsType = {};

export function loginRedirect(WrappedComponent: ComponentType) {
  const RedirectComponent: React.FC<MapPropsType & DispatchPropsType> = (
    props
  ) => {
    let { isAuth, ...restProps } = props;

    if (!isAuth) return <Navigate to={"/auth"} />;

    return <WrappedComponent {...(restProps)} />;
  };

  let ConnectedAuthRedirectComponent = connect<
    MapPropsType,
    DispatchPropsType,
    AppStateType
  >(
    mapStateToPropsForRedirect,
    {}
  )(RedirectComponent);

  return ConnectedAuthRedirectComponent;
}
