import {
  Action,
  createStore,
  combineReducers,
  applyMiddleware,
  compose,
} from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import thunk, { ThunkAction } from "redux-thunk";
import { phoneDetailsReducer } from "./reducers/PhoneDetailsReducer";
import { locationReducer } from "./reducers/LocationReducer";
import authReducer from "./reducers/AuthReducer";

const persistConfig = {
  key: "root",
  storage: storage,
  stateReconciler: autoMergeLevel2,
};

const rootReducer: any = combineReducers({
  phone: phoneDetailsReducer,
  login: authReducer,
  location: locationReducer,
});

const pReducer = persistReducer(persistConfig, rootReducer);

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  pReducer,
  composeEnhancers(applyMiddleware(thunk))
);

// store.subscribe(() => console.log(store.getState()));

export const persistor = persistStore(store);

type RootReducerType = typeof rootReducer;

export type AppStateType = ReturnType<RootReducerType>;

export type InferActionTypes<T> = T extends {
  [key: string]: (...args: any[]) => infer U;
}
  ? U
  : never;

export type DefaultThunkType<A extends Action> = ThunkAction<
  Promise<void>,
  AppStateType,
  unknown,
  A
>;
