import React, { forwardRef } from "react";

import { closeDarkIcon } from "icons/Icons";

type ToastProps = {
  id?: string;
  icon: string;
  title: string;
  subtitle: string;
  link: string;
  onClickProp: any;
  onClickLinkProp?: any;
};

function ToastWithRef(props: ToastProps, ref: any) {
  const { id, icon, title, subtitle, link, onClickProp, onClickLinkProp } =
    props;

  const onClick = (e: any) => {
    if (onClickProp) {
      onClickProp(id);
    }
  };

  return (
    <div id={id} className="toast-card toast-card-active d-flex bg-white p-4">
      {icon ? <img src={icon} /> : null}
      <div className="d-flex">
        <div>
          <p className="text-dark font-weight-semi-bold font-size-18 mb-1">
            {title}
          </p>
          <p className="text-body font-weight-regular font-size-16">
            {subtitle}{" "}
            <a
              className="text-secondary font-weight-semi-bold text-underline"
              onClick={onClickLinkProp}
            >
              {link}
            </a>
          </p>
        </div>
        <div>
          <span
            onClick={onClick}
            className="icon text-dark2"
            dangerouslySetInnerHTML={{ __html: closeDarkIcon }}
          ></span>
        </div>
      </div>
    </div>
  );
}

const Toast = forwardRef(ToastWithRef);

export default Toast as typeof ToastWithRef;
