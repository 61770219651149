import StartFlow from "components/flow/StartFlow";
import Menu from "components/Menu";
import Navbar from "components/Navbar";
import { loginRedirect } from "../hoc/LoginRedirect";

const Dashboard = () => {
  return (
    <>
      <Menu></Menu>
      <div className="page-content">
        <Navbar
          title="welcome.title"
          subtitle="welcome.subtitle"
          showCard={true}
          showGoBackButton={false}
          showCancelButton={false}
        ></Navbar>
        {/* <StartFlow></StartFlow> */}
      </div>
    </>
  );
};

export default loginRedirect(Dashboard);
