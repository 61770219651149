export const setLocationsAction = (locations: string[]) => {
  return {
    type: "SET_LOCATIONS",
    payload: locations
  };
};

export const setLocationIdAction = (locationId: number) => {
  return {
    type: "SET_LOCATION_ID",
    payload: locationId,
  };
};

export const setLocationNameAction = (locationName: string) => {
  return {
    type: "SET_LOCATION_NAME",
    payload: locationName,
  };
};

export const resetLocationStateAction = () => {
  return {
    type: "RESET_LOCATION",
  };
};