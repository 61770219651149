import { IStepperHeaderProps } from "interfaces/stepper";
import { useTranslation } from "react-i18next";

const StepperHeader = (props: IStepperHeaderProps) => {
  const { t } = useTranslation();
  
  return (
    <div>
      <div className="step-header-container d-flex flex-row justify-content-between mb-5">
        {props.steps.map((step) => (
          <div className="w-25" key={step.id + 1}>
            <div className="d-flex flex-column step-header-item">
              <div className="text-body text-align-start">
                {t('general.step')} {step.id + 1}
              </div>
              <div
                className={`title-text text-align-start  mb-3 ${step.isActive || step.isCompleted ? "text-dark" : "text-body"
                  }`}
              >
                {t(step.title)}
              </div>
              <div className="container-state">
                <div
                  className={`state ${step.isActive
                      ? "active"
                      : step.isCompleted
                        ? "completed"
                        : "default"
                    }`}
                ></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StepperHeader;
