import { useState, useEffect } from "react";

import { motion } from "framer-motion";

import Menu from "components/Menu";
import Navbar from "components/Navbar";
import Search from "components/organisms/Search";
import TableComponent from "components/organisms/Table";

import { downloadIcon } from "icons/Icons";
import { loginRedirect } from "hoc/LoginRedirect";
import { topToBottomSlideIn } from "utils/animations";
import { useTranslation } from "react-i18next";

const Docs = () => {
  const [stateData, setStateData] = useState<any[]>([]);
  const [elsPerPage, setElsPerPage] = useState(10);
  const [counter, setCounter] = useState(11);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState<number[]>([]);
  const [selectOptions, setSelectOptions] = useState<string[]>([]);
  const [searchBy, setSearchBy] = useState<any>();

  const { t } = useTranslation();

  useEffect(() => {
    setStateData(data1);
    setCounter(data1.length);
    buildSelectOptions();
  }, []);

  useEffect(() => {
    let firstSelectOption = firstLetterToLowerCase(selectOptions[0]);
    setSearchBy(firstSelectOption);
  }, [selectOptions]);

  useEffect(() => {
    handlePages();
    onChange(currentPage);
  }, [counter, elsPerPage]);

  const buildSelectOptions = () => {
    let dataElement: object = data1[0];
    let keys = Object.keys(dataElement);
    keys.forEach((element, index) => {
      keys[index] = keys[index].replace("_", " ");
      keys[index] = keys[index].charAt(0).toUpperCase() + keys[index].slice(1);
    });

    setSelectOptions(keys);
  };

  const handlePages = () => {
    let nrOfPages = Math.ceil(counter / elsPerPage);
    let pagesArray: number[] = [];
    for (let i = 1; i <= nrOfPages; i++) {
      pagesArray.push(i);
    }
    setPages(pagesArray);
  };

  const onChange = (pageNumber: string | number) => {
    setStateData(
      data1.slice(
        (Number(pageNumber) - 1) * elsPerPage,
        Number(pageNumber) * elsPerPage
      )
    );

    setCurrentPage(Number(pageNumber));
  };

  const handleElsPerPage = (e: any) => {
    if (e < counter) {
      setElsPerPage(e);
    } else {
      setElsPerPage(counter);
    }
  };

  const handleSearch = (e: any) => {
    const filteredData = data1.filter((el: any) => {
      if (
        el[searchBy]
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        return el;
      }
    });
    setStateData(
      filteredData.slice(
        (Number(currentPage) - 1) * elsPerPage,
        Number(currentPage) * elsPerPage
      )
    );
  };

  const handleSelect = (val: any) => {
    let newVal = firstLetterToLowerCase(val);
    setSearchBy(newVal);
  };

  const firstLetterToLowerCase = (val: string) => {
    if (val) {
      val = val.replace(" ", "_");
      val = val.charAt(0).toLowerCase() + val.slice(1);
      return val;
    }
  };

  const data1 = [
    {
      id: 245666,
      key: 24566,
      nume_client: "S****** A*****",
      data_receptie: "12.07.2021",
    },
    {
      id: 245667,
      key: 24567,
      nume_client: "Popescu Mara",
      data_receptie: "12.07.2021",
    },
  ];

  const columns = [
    {
      title: "ID Flip",
      dataIndex: "id",
      key: "id",
      width: 150,
    },
    {
      title: t("general.full-name"),
      dataIndex: "nume_client",
      key: "nume_client",
      width: 150,
    },
    {
      title: t("reception-date"),
      dataIndex: "data_receptie",
      key: "data_receptie",
      width: 150,
    },
    {
      title: t("gdpr-declaration"),
      key: "declaratii_gdpr",
      dataIndex: "declaratii_gdpr",
      width: 200,
      render: () => (
        <div className="d-flex align-items-center">
          <span
            className="icon start"
            dangerouslySetInnerHTML={{ __html: downloadIcon }}
          ></span>

          <a className="ml-2">{t("download-declaration")}</a>
        </div>
      ),
    },
    {
      title: t("pv"),
      key: "proces_verbal",
      dataIndex: "proces_verbal",
      width: 200,
      render: () => (
        <div className="d-flex align-items-center">
          <span
            className="icon start"
            dangerouslySetInnerHTML={{ __html: downloadIcon }}
          ></span>

          <a className="ml-2">{t("download-pv")}</a>
        </div>
      ),
    },
  ];

  return (
    <>
      <Menu></Menu>
      <div className="page-content">
        <Navbar
          title={t("in-house")}
          subtitle={t("client-docs")}
          showCard={false}
          showGoBackButton={false}
          showCancelButton={false}
        ></Navbar>
        <motion.div
          initial="hidden"
          animate="enter"
          exit="exit"
          variants={topToBottomSlideIn}
          className="content d-flex"
        >
          <div className="flex-1 flow-container w-100 m-5">
            <Search
              selectDefaultValue="id"
              selectOptions={selectOptions}
              onChange={handleSearch}
              handleSelect={handleSelect}
            />

            <TableComponent
              data={stateData}
              columns={columns}
              currentPage={currentPage}
              total={data1.length}
              pageSize={elsPerPage}
              pages={pages}
              handleElementsPerPage={handleElsPerPage}
              handlePage={onChange}
            />
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default loginRedirect(Docs);
