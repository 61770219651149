export const fixBody = () => {
    const body = document.getElementsByTagName("body")[0];
    body.classList.add("fixed");
}

export const unfixBody = () => {
    const body = document.getElementsByTagName("body")[0];
    body.classList.remove("fixed");
}

export const getFullDate = (date: any): string => {
    const dateAndTime = date.split('T');
    return dateAndTime[0].split('-').reverse().join('-');
};

export const buildOptionString = (string: string) => {
    string = string.replace("_", " ");
    string = string.charAt(0).toUpperCase() + string.slice(1);
    return string;
  };

export const buildTodayDate = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();
    let todayDate2String = dd + "/" + mm + "/" + yyyy;
    return todayDate2String;
};

export const capitalize = (string: string) => {
    return string.charAt(0).toLocaleUpperCase() + string.slice(1).toLocaleLowerCase();
}   