import { forwardRef } from "react";

type TabProps = {
  title: string;
  number: number | string;
  isActive: boolean;
  classNameTab?: string;
  classNameTitle?: string;
  classNameNumber?: string;
  onClickProp?: any;
};

const TabWithRef = (props: TabProps, ref: any) => {
  const {
    title,
    number,
    isActive,
    classNameTab,
    classNameTitle,
    classNameNumber,
    onClickProp,
  } = props;

  return (
    <div
      onClick={onClickProp}
      className={`tab d-flex ${(isActive && "active") || ""} ${
        (classNameTab && classNameTab) || ""
      }`}
    >
      <p className={`tab-title ${(classNameTitle && classNameTitle) || ""}`}>
        {title}
      </p>
      <span
        className={`tab-number ${(classNameNumber && classNameNumber) || ""}`}
      >
        {number}
      </span>
      <span className="tab-line"></span>
    </div>
  );
};

const Tab = forwardRef(TabWithRef);

export default Tab as typeof TabWithRef;
