import { useEffect, useLayoutEffect, useState } from "react";
import { arrowLeft, pencilIcon } from "icons/Icons";
import Button from "./organisms/Button";
import Popup from "./organisms/Popup";
import Textarea from "./organisms/Textarea";
import { Select } from "antd";

import { useDispatch, useSelector } from "react-redux";

import {
  resetStateAction,
  setCancelReasonAction,
  setCancelAdditionalInformationAction,
} from "redux/actions/PhoneDetailsAction";

import {
  setLocationIdAction,
  setLocationNameAction,
} from "redux/actions/LocationAction";

import { buybackAPI } from "api/buybackAPI";

import { selectOptions as options } from "utils/constants";

import { fixBody, unfixBody } from "utils/functions";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const { Option } = Select;

type NavbarProps = {
  title: string;
  subtitle: string;
  showCard: boolean;
  showGoBackButton: boolean;
  showCancelButton: boolean;
  showPopupProp?: boolean;
  onClickProp?: any;
  closePopupProp?: any;
};

const Navbar = (props: NavbarProps) => {
  const { t } = useTranslation();
  const router = useLocation();

  const dispatch = useDispatch();

  const { role } = useSelector((state: any) => state.login);

  const { buybackID, reason, additionalInformation } = useSelector(
    (state: any) => state.phone
  );

  const { locations, locationId, locationName } = useSelector(
    (state: any) => state.location
  );

  const [locs, setLocs] = useState<any[]>(locations);
  const [isLocationSelectOpen, setIsLocationSelectOpen] =
    useState<boolean>(false);
  const [showPopup, setShowPopup] = useState(false);

  useLayoutEffect(() => {
    setTimeout(() => {
      setSearchLocationsPlaceholder();
    }, 1000);
  }, []);

  useEffect(() => {
    focusSearchLocations();
  }, [isLocationSelectOpen]);

  useEffect(() => {
    if (router.pathname.includes('management')) {
      const label = t("general.all-locations");
      let allLocations:any ={};
      allLocations[label] = "ALL";
      const newLocations = [
        allLocations,
        ...locations
      ];
      setLocs(newLocations);

    } else {
      setLocs(locations);
      if(locations && locations.length){
        const firstLocation = locations[0];
        const firstLocationKey = Object.keys(firstLocation)[0];
        // @ts-ignore
        const firstLocationPair = firstLocation[Object.keys(firstLocation)[0]];
  
        dispatch(setLocationNameAction(firstLocationKey))
        dispatch(setLocationIdAction(firstLocationPair))
      }
    }
  }, [router])

  const setSearchLocationsPlaceholder = () => {
    const searchLocationsInput = document.getElementsByClassName(
      "ant-select-selection-search-input"
    )[0];
    if (searchLocationsInput) {
      (searchLocationsInput as HTMLInputElement).placeholder = t("general.search-location");
    }
  };

  const focusSearchLocations = () => {
    const searchLocationsInput = document.getElementsByClassName(
      "ant-select-selection-search-input"
    )[0];
    if (searchLocationsInput && isLocationSelectOpen) {
      (searchLocationsInput as HTMLInputElement).style.display = "block";
      (searchLocationsInput as HTMLInputElement).focus();
    } else if (searchLocationsInput && !isLocationSelectOpen) {
      (searchLocationsInput as HTMLInputElement).style.display = "none";
    }
  };

  const closePopup = () => {
    setShowPopup(false);
    props.closePopupProp(false);
    unfixBody();
  };

  const displayPopup = () => {
    setShowPopup(true);
    fixBody();
  };

  const handleSubmit = () => {
    dispatch(resetStateAction());
    closePopup();
  };

  const rejectBuyback = async () => {
    try {
      const response = await buybackAPI.buybackReject(
        buybackID,
        locationId,
        reason,
        additionalInformation
      );
    } catch (error) { }
  };

  return (
    <>
      <div className="navbar">
        <div className={`navbar-container ${(!props.showCard && "") || ""}`}>
          <div className="d-flex justify-content-between align-items-center h-100">
            <div className="navbar-left d-flex">
              {props.showGoBackButton && (
                <Button
                  variant="grey"
                  size="square"
                  startIcon={arrowLeft}
                  onClick={() => props.onClickProp()}
                ></Button>
              )}
              <div className={`${(props.showGoBackButton && "ml-3") || ""}`}>
                <div className="welcome text-body">{props.title}</div>
                <div
                  className="welcome-disclaimer text-dark"
                  dangerouslySetInnerHTML={{ __html: props.subtitle }}
                ></div>
              </div>
            </div>
            <div className="navbar-right">
              {props.showCard && role === "ADMIN_BUYBACK" && (
                <div className="location-card">
                  <div className="d-flex align-items-center">
                    <div className="location-icon d-flex">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.8">
                          <path
                            d="M12.0002 6.50085C11.2527 6.50085 10.522 6.71586 9.90055 7.11869C9.27906 7.52152 8.79467 8.09408 8.50862 8.76396C8.22258 9.43385 8.14774 10.171 8.29357 10.8821C8.43939 11.5933 8.79932 12.2465 9.32786 12.7592C9.85639 13.2719 10.5298 13.621 11.2629 13.7625C11.996 13.904 12.7559 13.8314 13.4464 13.5539C14.137 13.2764 14.7272 12.8065 15.1425 12.2036C15.5577 11.6008 15.7794 10.892 15.7794 10.1669C15.7794 9.1946 15.3812 8.26213 14.6725 7.57461C13.9637 6.8871 13.0025 6.50085 12.0002 6.50085ZM12.0002 11.9999C11.6264 11.9999 11.2611 11.8924 10.9504 11.691C10.6396 11.4896 10.3974 11.2033 10.2544 10.8684C10.1114 10.5334 10.074 10.1649 10.1469 9.80929C10.2198 9.45372 10.3997 9.12711 10.664 8.87076C10.9283 8.6144 11.265 8.43983 11.6315 8.3691C11.9981 8.29837 12.378 8.33467 12.7233 8.47341C13.0686 8.61214 13.3637 8.84709 13.5713 9.14853C13.779 9.44997 13.8898 9.80436 13.8898 10.1669C13.8898 10.653 13.6907 11.1193 13.3363 11.463C12.982 11.8068 12.5013 11.9999 12.0002 11.9999Z"
                            fill="#8892A4"
                          />
                          <path
                            d="M12 22.9981C11.2044 23.002 10.4194 22.821 9.7108 22.4702C9.00216 22.1194 8.39047 21.609 7.92695 20.9818C4.32631 16.1637 2.5 12.5416 2.5 10.2155C2.5 7.77141 3.50089 5.42741 5.28249 3.69916C7.06408 1.97092 9.48044 1 12 1C14.5196 1 16.9359 1.97092 18.7175 3.69916C20.4991 5.42741 21.5 7.77141 21.5 10.2155C21.5 12.5416 19.6737 16.1637 16.073 20.9818C15.6095 21.609 14.9978 22.1194 14.2892 22.4702C13.5806 22.821 12.7956 23.002 12 22.9981ZM12 3.00074C10.0276 3.00293 8.13671 3.76395 6.74205 5.11685C5.34738 6.46975 4.56287 8.30406 4.56062 10.2173C4.56062 12.0595 6.34913 15.4662 9.59547 19.8096C9.87107 20.1778 10.2326 20.4775 10.6505 20.6841C11.0685 20.8908 11.5309 20.9985 12 20.9985C12.4691 20.9985 12.9315 20.8908 13.3495 20.6841C13.7674 20.4775 14.1289 20.1778 14.4045 19.8096C17.6509 15.4662 19.4394 12.0595 19.4394 10.2173C19.4371 8.30406 18.6526 6.46975 17.258 5.11685C15.8633 3.76395 13.9724 3.00293 12 3.00074Z"
                            fill="#8892A4"
                          />
                        </g>
                      </svg>
                    </div>
                    <div className="ml-3 location-text">
                      <div className="mr-4">
                        <div className="current-disclaimer text-body">
                          {t("current-location")}
                        </div>
                        <Select
                          showSearch={isLocationSelectOpen ? true : false}
                          showArrow={false}
                          open={isLocationSelectOpen}
                          defaultValue={locationName}
                          value={isLocationSelectOpen ? "" : locationName}
                          className={`location-select text-dark2 w-100 ${isLocationSelectOpen && "location-select-open"
                            }`}
                          dropdownStyle={{
                            zIndex: 9999,
                            background: "white",
                          }}
                          onChange={(location) => {
                            const newLocation = JSON.parse(location);
                            setIsLocationSelectOpen(false);
                            dispatch(
                              setLocationIdAction(
                                newLocation[Object.keys(newLocation)[0]]
                              )
                            );
                            dispatch(
                              setLocationNameAction(Object.keys(newLocation)[0])
                            );
                          }}
                          onBlur={() => setIsLocationSelectOpen(false)}
                          placement={"bottomRight"}
                        >
                          {locs &&
                            locs.map((loc: any, index: any) => (
                              <Option key={index} value={JSON.stringify(loc)}>
                                {Object.keys(loc)[0]}
                              </Option>
                            ))}
                        </Select>
                      </div>
                    </div>

                    <span
                      className="cursor-pointer z-index-99"
                      dangerouslySetInnerHTML={{ __html: pencilIcon }}
                      onClick={() => setIsLocationSelectOpen(true)}
                    ></span>
                  </div>
                </div>
              )}
              {props.showCancelButton && (
                <Button
                  variant="body"
                  size="medium"
                  onClick={() => displayPopup()}
                >
                  {t("general.quit")}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      {props.showPopupProp || showPopup ? (
        <Popup
          icon={"/images/thumbs-down.png"}
          headerText={t("offer-rejected")}
          description={t("offer-rejected-descr")}
          closeHandler={closePopup}
        >
          <Select
            className="popup-select w-100 mb-2"
            defaultValue={t("choose-reason")}
            dropdownStyle={{ zIndex: 9999, background: "white" }}
            onChange={(value) => dispatch(setCancelReasonAction(value))}
          >
            {options &&
              options.map((selectOption, index) => (
                <Option key={index} value={t(`refuseReason.${selectOption}`)}>
                  {t(`refuseReason.${selectOption}`)}
                </Option>
              ))}
          </Select>
          <Textarea
            id="informatii"
            name="informatii"
            placeholder={t("extra-info")}
            onChange={(e: any) =>
              dispatch(setCancelAdditionalInformationAction(e.target.value))
            }
          />

          <Button
            variant="primary"
            size="large"
            className="w-100 mt-5"
            onClick={() => {
              rejectBuyback();
              handleSubmit();
            }}
          >
            {t("general.confirm")}
          </Button>
        </Popup>
      ) : null}
    </>
  );
};
export default Navbar;
