import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PhoneDetails from "./PhoneDetails";
import { IPhoneDetails } from "interfaces/stepper";

import { buybackAPI } from "../../../../api/buybackAPI";

import { setBuybackOfferAction } from "../../../../redux/actions/PhoneDetailsAction";
import { useTranslation } from "react-i18next";

interface BuyBackOfferProps { }
interface PhoneDetailsProps {
  phoneDetails: IPhoneDetails[];
}

const BuyBackOffer = (props: PhoneDetailsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { buybackID, defects, repairs } = useSelector(
    (state: any) => state.phone
  );

  return (
    <div className="d-flex flex-column">
      <h3 className="font-size-16 font-weight-semi-bold text-dark2 text-align-start">
        {t("general.client-phone-info")}

      </h3>
      <div className="font-size-14 font-weight-medium text-body text-align-start mb-3 pt-4">
        {t("general.phone-data")}
      </div>
      <PhoneDetails
        phoneDetails={props.phoneDetails}
        directionContent="row"
      ></PhoneDetails>
      <div className="font-size-14 font-weight-medium text-body text-align-start mb-3 pt-4">
        {t("general.defects-found")}
      </div>
      <div className="d-flex flex-wrap">
        {defects.length !== 0 &&
          defects.map((defect: any, index: number) => (
            <div key={index} className="d-flex defect-item mr-4 mb-4">
              <div className="font-weight-medium font-size-16 text-dark2">
                {t(`repairs.${defect.toUpperCase()}`)}
              </div>
            </div>
          ))}
        {repairs.length !== 0 &&
          repairs.map((repair: any, index: number) => (
            <div key={index} className="d-flex defect-item mr-4 mb-4">
              <div className="font-weight-medium font-size-16 text-dark2">
                {t(`repairs.${repair.toUpperCase()}`)}
              </div>
            </div>
          ))}
        {repairs.length === 0 && defects.length === 0 && <p>N/A</p>}
      </div>
    </div>
  );
};

export default BuyBackOffer;
