import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Popup from "./organisms/Popup";
import Button from "./organisms/Button";

import {
  resetStateAction,
  setIsBuybackProcessDoneAction,
} from "redux/actions/PhoneDetailsAction";

import { unfixBody } from "utils/functions";
import { useTranslation } from "react-i18next";

const PopupSuccess = () => {
  const { t } = useTranslation();
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Popup
      icon="/images/thumbs-up.png"
      headerText={t("finalize")}
      description={t("finalize-descr")}
    >
      <Button
        variant="primary"
        size="large"
        className="w-100 mt-5"
        onClick={() => {
          unfixBody();
          dispatch(resetStateAction());
          dispatch(setIsBuybackProcessDoneAction(true));
          navigate("/in-management-phones");
        }}
      >
         {t("general.confirm")}
      </Button>
    </Popup>
  );
};

export default PopupSuccess;
