import Input, { InputVariant } from "../../components/organisms/Input";
import Button from "../../components/organisms/Button";
import { eyeIcon, errorIcon, checkedIcon } from "../../icons/Icons";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { userLogin, setRoleAction } from "../../redux/reducers/AuthReducer";
import { AppStateType } from "../../redux/Store";
import { authAPI } from "api/authAPI";
import { useTranslation } from "react-i18next";

const LoginForm = () => {
  const { t } = useTranslation();

  const [passwordType, setPasswordType] = useState<boolean>(false);
  const [emailVariant, setEmailVariant] = useState<InputVariant>("outlined");
  const [passwordVariant, setPasswordVariant] =
    useState<InputVariant>("password");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [localError, setLocalError] = useState("");
  const [waiting, setWaiting] = useState(false);

  const error = useSelector((state: AppStateType) => state.login.error);

  const dispatch = useDispatch();

  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    if (error.message !== "") {
      setEmailVariant("error");
      setPasswordVariant("error");
      setEmail("");
      setPassword("");
      setPassword("");
      setLocalError(error.message);
      setWaiting(false);
    }
  }, [error]);

  const handleReCaptchaVerify = useCallback(
    async (email: string, password: string) => {
      if (!executeRecaptcha) {
        return;
      }

      const _token = await executeRecaptcha("setTokenAction");
      dispatch(userLogin(email, password, _token, t));
      setWaiting(true);
    },
    [executeRecaptcha]
  );

  function validateEmail(email: string) {
    const mailFormat = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.+-]+\.[a-zA-Z]{2,4}$/;

    if (email == "" || !email.match(mailFormat)) {
      return false;
    }
    return true;
  }

  function loginButtonHandler(e: any) {
    e.preventDefault();
    if (!validateEmail(email)) {
      setEmailVariant("error");
    } else if (password == "") {
      setPasswordVariant("error");
    } else {
      handleReCaptchaVerify(email, password);
    }
  }

  function emailOnBlurHandler(e: React.FormEvent<HTMLInputElement>) {
    const email = e.currentTarget.value;
    if (!validateEmail(email)) {
      setEmailVariant("error");
    } else {
      setEmailVariant("valid");
    }
  }

  function emailOnChangeHandler(e: React.FormEvent<HTMLInputElement>) {
    setEmail(e.currentTarget.value);
    if (!validateEmail(e.currentTarget.value)) {
      setEmailVariant("error");
    } else {
      setEmailVariant("valid");
    }
    setLocalError("");
  }

  function passwordOnBlurHandler(e: React.FormEvent<HTMLInputElement>) {
    if (e.currentTarget.value == "") {
      setPasswordVariant("error");
      setEmailVariant("error");
    } else {
      setPasswordVariant("password");
    }
  }

  function passwordOnChangeHandler(e: React.FormEvent<HTMLInputElement>) {
    setPassword(e.currentTarget.value);
    setPasswordVariant("password");
    setLocalError("");
  }

  function toggleShowPassword() {
    setPasswordType(!passwordType);
  }

  return (
    <div className="d-flex justify-content-center align-items-center w-50">
      <form
        className="login-form  d-flex flex-column"
        onSubmit={loginButtonHandler}
      >
        <div className="login-form-description">
          <h1 className="text-dark2"> {t("login.title")}</h1>
          <div className="login-description-text text-body">
            {t("login.description")}
          </div>
        </div>
        <Input
          id={"email"}
          className="relative"
          variant={emailVariant}
          type={"text"}
          startLabel={t("general.email-label")}
          endIcon={
            emailVariant === "error"
              ? errorIcon
              : emailVariant === "valid"
                ? checkedIcon
                : ""
          }
          size={"medium"}
          placeholder={t("general.email-placeholder")}
          value={email}
          onBlur={emailOnBlurHandler}
          onChange={emailOnChangeHandler}
        />
        <Input
          id={"password"}
          className="relative"
          variant={passwordVariant}
          type={passwordType ? "text" : "password"}
          startLabel={t("general.pass-label")}
          endIcon={passwordVariant === "error" ? errorIcon : eyeIcon}
          size={"medium"}
          placeholder={t("general.pass-placeholder")}
          value={password}
          onClick={toggleShowPassword}
          onBlur={passwordOnBlurHandler}
          onChange={passwordOnChangeHandler}
        />
        {localError && <p className="login-form-error">{t(`login.${localError}`)}</p>}
        <Button
          type="submit"
          variant={"primary"}
          className="w-100"
          onClick={() => { }}
          wait={waiting}
        >
           {t("login.btn")}
        </Button>
      </form>
    </div>
  );
};

export default LoginForm;
