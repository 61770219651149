import { forwardRef, ReactNode } from "react";

type TabsProps = {
  children: ReactNode | ReactNode[];
};

const TabsWithRef = (props: TabsProps, ref: any) => {
  const { children } = props;
  return <div className="tabs d-flex w-100">{children}</div>;
};

const Tabs = forwardRef(TabsWithRef);

export default Tabs as typeof TabsWithRef;
