import { useSelector } from "react-redux";

import { loadingIcon } from "../../../icons/Icons";
import { useTranslation } from "react-i18next";

interface StepperContentRenderedProps {
  idSelectedStep?: number;
  isLoadingNewOffer?: boolean;
  showDisclaimer?: boolean;
  children: React.ReactNode | React.ReactNode[];
}
const StepperContentRendered = (props: StepperContentRenderedProps) => {
  const { buybackOffer } = useSelector((state: any) => state.phone);
  const { idSelectedStep, isLoadingNewOffer, showDisclaimer } = props;

  const { t } = useTranslation();

  return (
    <div className="step-content-container w-80mx-auto flex-1">
      <div className="step-content-card">{props.children}</div>
      {idSelectedStep === 3 && (
        <div className="d-flex flex-column align-items-center justify-content-center step-content-card mt-4 offer-content">
          <div className="font-size-16 font-weight-semi-bold text-body text-align-start">
            {t("buyback-offer")}
          </div>
          {!isLoadingNewOffer ? (
            <>
              <h2 className="font-size-26 font-weight-bold text-align-start text-white">
                {buybackOffer}   {t("general.currency")}
              </h2>

              {showDisclaimer && (
                <p className="font-size-12 text-align-start text-white">
                  {t("explanation-lower")}
                </p>
              )}
            </>
          ) : (
            <span
              className="icon end"
              dangerouslySetInnerHTML={{ __html: loadingIcon }}
            ></span>
          )}
        </div>
      )}
    </div>
  );
};

export default StepperContentRendered;
