import { IPhoneDefects, IPhoneDetails, IProcessStep } from "interfaces/stepper";

export const setSellerNameAction = (sellerName: string) => {
  return {
    type: "SET_SELLER_NAME",
    payload: sellerName,
  };
};

export const setBuybackIDAction = (buybackID: string) => {
  return {
    type: "SET_BUYBACK_ID",
    payload: buybackID,
  };
};

export const setSellOrderIDAction = (sellOrderID: number) => {
  return {
    type: "SET_SELL_ORDER_ID",
    payload: sellOrderID,
  };
};

export const setPhoneDetailsAction = (phoneDetails: IPhoneDetails[]) => {
  return {
    type: "SET_PHONE_DETAILS",
    payload: phoneDetails,
  };
};

export const setIMEIAction = (IMEI: string) => {
  return {
    type: "SET_IMEI",
    payload: IMEI,
  };
};

export const setRepairsAction = (repairs: IPhoneDefects[] | string[]) => {
  return {
    type: "SET_REPAIRS",
    payload: repairs,
  };
};

export const setUncheckedRepairsActions = (
  repairs: IPhoneDefects[] | string[]
) => {
  return {
    type: "SET_UNCHECKED_REPAIRS",
    payload: repairs,
  };
};

export const setDefectsAction = (defects: string[]) => {
  return {
    type: "SET_DEFECTS",
    payload: defects,
  };
};

export const setBuybackOfferAction = (buybackOffer: number) => {
  return {
    type: "SET_BUYBACK_OFFER",
    payload: buybackOffer,
  };
};

export const setGDPRAction = (gdpr: string) => {
  return {
    type: "SET_GDPR",
    payload: gdpr,
  };
};

export const setProtocolAction = (protocol: string) => {
  return {
    type: "SET_PROTOCOL",
    payload: protocol,
  };
};

export const setCurrentStepAction = (currentStep: IProcessStep) => {
  return {
    type: "SET_CURRENT_STEP",
    payload: currentStep,
  };
};

export const setStepsAction = (steps: any[]) => {
  return {
    type: "SET_STEPS",
    payload: steps,
  };
};

export const setCurrentStepperFlowStepAction = (currentStep: number) => {
  return {
    type: "SET_CURRENT_STEPPER_FLOW_STEP",
    payload: currentStep,
  };
};

export const setStepperFlowStepsAction = (steps: any[]) => {
  return {
    type: "SET_STEPPER_FLOW_STEPS",
    payload: steps,
  };
};

export const setCancelReasonAction = (cancelReason: string) => {
  return {
    type: "SET_CANCEL_REASON",
    payload: cancelReason,
  };
};

export const setCancelAdditionalInformationAction = (
  additionalInformation: string
) => {
  return {
    type: "SET_CANCEL_ADDITIONAL_INFORMATION",
    payload: additionalInformation,
  };
};

export const setIsBuybackProcessDoneAction = (
  isBuybackProcessDone: boolean
) => {
  return {
    type: "SET_IS_BUYBACK_PROCESS_DONE",
    payload: isBuybackProcessDone,
  };
};

export const setMarketingApprovalAction = (marketingApproval: boolean) => {
  return {
    type: "SET_MARKETING_APPROVAL",
    payload: marketingApproval,
  };
};

export const resetStateAction = () => {
  return {
    type: "RESET_STATE",
  };
};

export const setCustomerEmailAction = (customerEmail: string) => {
  return {
    type: "SET_CUSTOMER_EMAIL",
    payload: customerEmail,
  };
};

