import Button from "components/organisms/Button";
import Selector from "components/organisms/Selector";
import React, { useEffect } from "react";
import { IPhoneDefects } from "interfaces/stepper";
import { useDispatch, useSelector } from "react-redux";
import {
  setRepairsAction,
  setUncheckedRepairsActions,
} from "../../../../redux/actions/PhoneDetailsAction";
import { exclamationCircle } from "icons/Icons";
import { capitalize } from "../../../../utils/functions";
import { useTranslation } from "react-i18next";

interface PhoneRepairsProps {
  phoneDefectsDetected: string[];
  phoneRepairsPossible: IPhoneDefects[];
  setPhoneRepairs: any;
  phoneRepairs: IPhoneDefects[];
}

const PhoneRepairs = (props: PhoneRepairsProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const brand = useSelector((state: any) => state.phone.phoneDetails[0].value);
  const model = useSelector((state: any) => state.phone.phoneDetails[1].value);

  const phoneRepairsRedux = useSelector((state: any) => state.phone.repairs);
  const phoneUncheckedRepairsRedux = useSelector(
    (state: any) => state.phone.uncheckedRepairs
  );

  const [showSelector, setShowSelector] = React.useState<boolean>(false);
  const [finalPhoneRepairsPossible, setFinalPhoneRepairsPossible] =
    React.useState<IPhoneDefects[]>([]);
  const [addedPhoneRepairs, setAddedPhoneRepairs] = React.useState<
    IPhoneDefects[]
  >([]);
  const [displayQuestion, setDisplayQuestion] = React.useState<string>(
    brand === "Apple"
      ? t("true-tone-q")
      : t("burn-in-q")
  );
  const [displayFirstAnswer, setDisplayFirstAnswer] = React.useState<string>(
    brand === "Apple" ? t("yes-works") : t("no-burn")
  );
  const [displaySecondAnswer, setDisplaySecondAnswer] = React.useState<string>(
    brand === "Apple" ? t("no-problems") : t("has-burn")
  );

  const [showQuestion, setShowQuestion] = React.useState<boolean>(true);

  const [isOldIphone, setIsOldIphone] = React.useState<boolean>(
    model.replace(/\s+/g, "").toLowerCase().includes("iphone6") ||
      model.replace(/\s+/g, "").toLowerCase().includes("iphone7")
      ? true
      : false
  );

  useEffect(() => {
    const repairsArray = props.phoneDefectsDetected.map((item: string) => {
      return item;
    });
    const finalPhoneRepairsPossible = phoneUncheckedRepairsRedux.filter(
      (item: any) => repairsArray.indexOf(item.title) < 0
    );
    setFinalPhoneRepairsPossible(finalPhoneRepairsPossible);
  }, []);

  const onConfirmSelection = (selection: any) => {
    const selectionArray = selection.map((item: any) => {
      return item.title;
    });
    const unCheckedPhoneRepairs = finalPhoneRepairsPossible.filter(
      (item) => selectionArray.indexOf(item.title) < 0
    );

    setFinalPhoneRepairsPossible(unCheckedPhoneRepairs);
    dispatch(setUncheckedRepairsActions(unCheckedPhoneRepairs));
    dispatch(setRepairsAction(phoneRepairsRedux.concat(selection)));
    setShowSelector(false);
  };

  const removeRepair = (defect: any) => {
    setAddedPhoneRepairs([...addedPhoneRepairs]);
    const newPhoneRepairs = [...phoneRepairsRedux];
    const indexPhoneRepairs = newPhoneRepairs.indexOf(defect);
    newPhoneRepairs.splice(indexPhoneRepairs, 1);
    dispatch(setRepairsAction(newPhoneRepairs));

    finalPhoneRepairsPossible.push(defect);
    setFinalPhoneRepairsPossible(finalPhoneRepairsPossible);
    dispatch(setUncheckedRepairsActions(finalPhoneRepairsPossible));
  };

  const addDisplayDefect = () => {
    const repairsWoDisplay = finalPhoneRepairsPossible.filter(
      // @ts-expect-error
      (x) => x !== "DISPLAY"
    );
    dispatch(setUncheckedRepairsActions(repairsWoDisplay));
    dispatch(setRepairsAction(phoneRepairsRedux.concat("Display")));
  };

  return (
    <div
      className={`d-flex flex-column ${props.phoneDefectsDetected.length > 0 || phoneRepairsRedux.length > 0
        ? "align-items-start"
        : "align-items-start"
        } `}
    >
      {!props.phoneDefectsDetected.includes("LCDPixels") &&
        !phoneRepairsRedux.includes("Display") &&
        !phoneRepairsRedux.includes("DISPLAY") &&
        showQuestion &&
        !isOldIphone && (
          <div className="d-flex flex-column align-items-start">
            <div className="d-flex justify-content-center">
              <p className="font-size-16 font-weight-semi-bold text-dark mr-3">
                {displayQuestion}
              </p>
              {/* <span
                className="d-flex align-items-center"
                dangerouslySetInnerHTML={{ __html: exclamationCircle }}
              ></span> */}
            </div>

            <div className="d-flex  mt-3">
              <Button
                variant="outlined"
                size="small"
                className="w-100 mr-3"
                onClick={() => {
                  setShowQuestion(false);
                }}
              >
                {displayFirstAnswer}
              </Button>

              <Button
                variant="outlined"
                size="small"
                className="w-100"
                onClick={() => {
                  addDisplayDefect();
                }}
              >
                {displaySecondAnswer}
              </Button>
            </div>
          </div>
        )}
      {/* {phoneRepairsRedux.length === 0 &&
        props.phoneDefectsDetected.length === 0 && (
          <div className="d-flex flex-column align-items-center">
            <div className="flex-1 flow-header-image-lg">
              <img
                src="/images/tool-dynamic-clay.png"
                className="img-fluid"
                alt="tools"
              />
            </div>
            <h2 className="font-size-18 font-weight-semi-bold text-dark pt-4">
              Telefonul nu are defecte identificate
            </h2>
            <div className="font-size-16 text-body mb-4 pt-3">
              Adauga defecte in cazul in care exista prin apasarea butonului
              urmator
            </div>
          </div>
        )} */}

      {/* {props.phoneDefectsDetected.length > 0 && (
        <div className="font-weight-semi-bold font-size-16 text-dark2 pb-4 float-left">
          Defectiuni identificate
        </div>
      )} */}

      <div
        className={`d-flex flex-row w-100 flex-wrap ${props.phoneDefectsDetected.length > 0 || phoneRepairsRedux.length > 0
          ? "justify-content-start"
          : "justify-content-start"
          } `}
      >
        <div className="relative">
          <div className="mt-4">
            <p className="font-size-16 font-weight-semi-bold text-dark text-align-start mb-3">
              {phoneRepairsRedux.length === 0 &&
                props.phoneDefectsDetected.length === 0
                ? t("no-defects")
                : t("found-defects")}
            </p>
            <div className="d-flex flex-wrap">
              {props.phoneDefectsDetected.map(
                (defect: string, index: number) => (
                  <div key={index} className="d-flex defect-item mr-4 mb-4">
                    <div className="font-weight-medium font-size-16 text-dark2">
                      {t(`repairs.${defect.toUpperCase()}`)}
                    </div>
                  </div>
                )
              )}
              {phoneRepairsRedux.map((repair: any, index: number) => (
                <div
                  key={index}
                  className="d-flex align-items-center defect-item mr-4 mb-4"
                >
                  {repair !== "Display" && (
                    <div
                      className="text-body pr-3 remove-defect"
                      onClick={() => removeRepair(repair)}
                    >
                      X
                    </div>
                  )}
                  <p className="font-weight-medium font-size-16 text-dark2">
                  {t(`repairs.${repair.toUpperCase()}`)}
                  </p>
                </div>
              ))}
              <div className="relative">
                <Button
                  className="relative"
                  variant="secondary"
                  size="small"
                  onClick={() => setShowSelector(!showSelector)}
                >
                  + {t("add-defects")}
                </Button>

                {showSelector && (
                  <Selector
                    data={phoneUncheckedRepairsRedux}
                    onConfirmSelection={(selection: any) => {
                      onConfirmSelection(selection);
                    }}
                    setShowSelector={setShowSelector}
                    displayKey="title"
                    confirmButtonMessage={t("confirm-defects")}
                  ></Selector>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhoneRepairs;
