import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

const DropdownLanguage = () => {
  const { i18n } = useTranslation();
  const [cookies, setCookie] = useCookies(["i18next"]);
  const [language, setLanguage] = useState(cookies.i18next || 'ro');

  const handleLangChange = (evt:any) => {
    const lang = evt.target.value;
    setLanguage(lang);
    i18n.changeLanguage(lang);
    setCookie("i18next", lang);
   

  };

  return (
    <select onChange={handleLangChange} value={language}>
      {/* <option value="en">EN</option> */}
      <option value="ro">🇷🇴</option>
      <option value="bg">🇧🇬</option>
      {/* <option value="hu">HU</option> */}
    </select>
  );
};

export default DropdownLanguage;


