import React, { forwardRef, useEffect, useState } from "react";
export type TextareaImperativeRef = React.MutableRefObject<
  | {
      toggleWaiting?: (mode?: boolean) => void;
    }
  | null
  | undefined
>;

type TextareaProps = {
  id?: string;
  name?: string;
  children?: React.ReactNode | React.ReactNode[];
  placeholder?: string;
  defaultValue?: string;
  className?: string;
  value?: string;
  iRef?: TextareaImperativeRef;
  register?: any;
  onClick?: any;
  onChange?: any;
  onBlur?: any;
};

function TextareaWithRef(props: TextareaProps, ref: any) {
  const {
    id,
    name,
    className: classNameProp,
    placeholder,
    defaultValue,
    children,
    onBlur: onBlurProp,
    onClick: onClickProp,
    onChange: onChangeProp,
    iRef,
    value,
    ...rest
  } = props;

  const onBlur = (e: any) => {
    if (onBlurProp) {
      onBlurProp(e);
    }
  };

  const onChange = (e: any) => {
    if (onChangeProp) {
      onChangeProp(e);
    }
  };

  return (
    <textarea
      id={id}
      name={name}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
    ></textarea>
  );
}

const Textarea = forwardRef(TextareaWithRef);

export default Textarea as typeof TextareaWithRef;
