import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { motion } from "framer-motion";

import Menu from "components/Menu";
import Navbar from "components/Navbar";
import Search from "components/organisms/Search";
import TableComponent from "components/organisms/Table";

import { buybackAPI } from "api/buybackAPI";

import { loginRedirect } from "hoc/LoginRedirect";

import { topToBottomSlideIn } from "utils/animations";
import { buildOptionString } from "utils/functions";
import { useTranslation } from "react-i18next";

const AcceptedModels = () => {
  const [data, setData] = useState<any[]>([]);
  const [stateData, setStateData] = useState<any[]>([]);
  const [elsPerPage, setElsPerPage] = useState(10);
  const [counter, setCounter] = useState(11);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState<number[]>([]);
  const [selectOptions, setSelectOptions] = useState<string[]>([]);
  const [searchBy, setSearchBy] = useState<any>("name");

  const {t} = useTranslation();

  enum ColumnsEnum {
    key="Index",
    name = "Model",
    storage = "Memorie",
    price = "Pret (de la)"
  }

  const columns = [
    {
      title:t("general.model"),
      dataIndex: "name",
      key: "name"
    },
    {
      title: t("general.memory"),
      dataIndex: "storage",
      key: "storage"
    },
    {
      title: t("general.price-from"),
      dataIndex: "price",
      key: "price",
      render: (sum: string) => <p>{Number(sum).toFixed(2)}</p>,
    },
  ];

  useEffect(() => {
    getModelsFromAPI();
    buildSelectOptions();
  }, []);

  useEffect(() => {
    setCounter(data.length);
  }, [data]);

  useEffect(() => {
    handlePages();
    onChange(currentPage);
  }, [counter, elsPerPage]);

  const getModelsFromAPI = async () => {
    try {
      const response = await buybackAPI.models();
      const { data } = response.data;

      const models = data.models;
      models.map((column:any, index:number) => {
        column['key'] = index;
      });
      setData(models);
      setStateData(models);
    } catch (error) { }
  };

  const buildSelectOptions = () => {
    let keys: string[] = [];

    columns.map((column) => {
      if (column.key !== "operation") {
        keys.push(buildOptionString(column.title));
      }
    });

    setSelectOptions(keys);
  };

  const handlePages = () => {
    let nrOfPages = Math.ceil(counter / elsPerPage);
    let pagesArray: number[] = [];
    for (let i = 1; i <= nrOfPages; i++) {
      pagesArray.push(i);
    }
    setPages(pagesArray);
  };

  const onChange = (pageNumber: string | number) => {
    setStateData(
      data.slice(
        (Number(pageNumber) - 1) * elsPerPage,
        Number(pageNumber) * elsPerPage
      )
    );

    setCurrentPage(Number(pageNumber));
  };

  const handleElsPerPage = (e: any) => {
    if (e < counter) {
      setElsPerPage(e);
    } else {
      setElsPerPage(counter);
    }
  };

  const handleSearch = (e: any) => {
    const filteredData = data.filter((el: any) => {
      if (el[searchBy]) {
        if (
          el[searchBy]
            .toString()
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          return el;
        }
      }
    });
    setStateData(
      filteredData.slice(
        (Number(currentPage) - 1) * elsPerPage,
        Number(currentPage) * elsPerPage
      )
    );
  };

  const handleSelect = (val: any) => {
    const valKey =
      Object.keys(ColumnsEnum)[
      Object.values(ColumnsEnum).indexOf(val as unknown as ColumnsEnum)
      ];
    valKey && setSearchBy(valKey);
  };



  return (
    <>
      <Menu></Menu>
      <div className="page-content">
        <Navbar
          title={t("in-house")}
          subtitle={t("menu.models")}
          showCard={false}
          showGoBackButton={false}
          showCancelButton={false}
        ></Navbar>
        <motion.div
          initial="hidden"
          animate="enter"
          exit="exit"
          variants={topToBottomSlideIn}
          className="content d-flex"
        >
          <div className="flex-1 flow-container w-100 m-5">
            <Search
              onChange={handleSearch}
              handleSelect={handleSelect}
              selectOptions={selectOptions}
              selectDefaultValue="Model"
            />
            <TableComponent
              pages={pages}
              data={stateData}
              columns={columns}
              total={data.length}
              pageSize={elsPerPage}
              handlePage={onChange}
              currentPage={currentPage}
              rowKey="key"
              handleElementsPerPage={handleElsPerPage}
            />
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default loginRedirect(AcceptedModels);
