import { IPhoneDetails } from "interfaces/stepper";

interface PhoneDetailsProps {
  phoneDetails: IPhoneDetails[];
  directionContent?: "column" | "row";
}

const PhoneDetails = (props: PhoneDetailsProps) => {
  return (
    <>
      <div
        className={`d-flex flex-row flex-wrap ${
          props.directionContent !== "row" && "justify-content-between"
        }`}
      >
        {props.phoneDetails.map((phoneDetail: IPhoneDetails, index: number) => (
          <div
            className={`
                    ${
                      props.directionContent === "row" ? "flex-23" : "flex-48"
                    }  
                    ${
                      props.directionContent === "column"
                        ? index < 2
                          ? "pb-4"
                          : ""
                        : ""
                    }
                    `}
            key={phoneDetail.key}
          >
            {props.directionContent === "column" && (
              <div className="d-flex flex-row phone-detail-card">
                <div
                  className="d-flex card-icon align-items-center justify-content-center"
                  style={{
                    background: `${phoneDetail.iconColor}`,
                  }}
                >
                  {phoneDetail.icon ? (
                    <img
                      src={`/images/${
                        phoneDetail.icon ? phoneDetail.icon : "brand"
                      }-icon.svg`}
                      className="img-fluid"
                    />
                  ) : null}
                </div>
                <div className="d-flex flex-column justify-content-between pl-3">
                  <h2 className="font-size-15 text-body text-align-start">
                    {phoneDetail.key}
                  </h2>
                  {phoneDetail.value !== "" && (
                    <h2 className="title-text phone-detail-title text-align-start text-dark2">
                      {phoneDetail.value}
                    </h2>
                  )}
                </div>
              </div>
            )}
            {props.directionContent === "row" && (
              <div className="d-flex flex-row phone-detail-card-row">
                <div className="d-flex card-icon align-items-center justify-content-center">
                  {phoneDetail.icon ? (
                    <img
                      src={`/images/${
                        phoneDetail.icon ? phoneDetail.icon : "brand"
                      }-colored-icon.svg`}
                      className="img-fluid"
                    />
                  ) : null}
                </div>
                <div className="d-flex flex-column justify-content-between pl-3">
                  <h2 className="font-size-13 text-body text-align-start">
                    {phoneDetail.key}
                  </h2>
                  {phoneDetail.value !== "" && (
                    <h2 className="font-size-15 title-text text-align-start text-dark2">
                      {phoneDetail.value}
                    </h2>
                  )}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default PhoneDetails;
