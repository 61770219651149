import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { motion } from "framer-motion";

import Menu from "components/Menu";
import Navbar from "components/Navbar";
import Popup from "components/organisms/Popup";
import Search from "components/organisms/Search";
import Button from "components/organisms/Button";
import TableComponent from "components/organisms/Table";

import { downloadIcon } from "icons/Icons";
import { buybackAPI } from "api/buybackAPI";
import { loginRedirect } from "hoc/LoginRedirect";
import { topToBottomSlideIn } from "utils/animations";

import { resetStateAction } from "redux/actions/PhoneDetailsAction";
import { buildOptionString } from "utils/functions";

import generatePhoneSheet from "components/PhoneSheet";
import { roleAdmin, roleSuperAdmin } from "utils/constants";
import { donwloadExcel, downloadFile } from "../utils/api";
import { useTranslation } from "react-i18next";
import { setLocationsAction } from "redux/actions/LocationAction";

const InManagementPhones = () => {
  const dispatch = useDispatch();
  const { locationId, locations } = useSelector((state: any) => state.location);
  const { role } = useSelector((state: any) => state.login);

  const [columns, setColumns] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [stateData, setStateData] = useState<any[]>([]);
  const [elsPerPage, setElsPerPage] = useState(10);
  const [counter, setCounter] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState<number[]>([]);
  const [selectOptions, setSelectOptions] = useState<string[]>([]);
  const [searchBy, setSearchBy] = useState<string>("id");
  const [brandsCount, setBrandsCount] = useState([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

  const { t } = useTranslation();

  enum ColumnsEnum {
    id = "ID Flip",
    name = "Model",
    storage = "Memorie",
    order_date = "Data receptie",
    offer = "Oferta",
    imei_code = "IMEI",
    defects = "Defecte",
    location = "Locatie",
  }

  useEffect(() => {
    dispatch(resetStateAction());
 
    (role === roleSuperAdmin || role === roleAdmin)
      ? setColumns(columnsSuperAdmin)
      : setColumns(columnsAdmin);
  }, []);

  useEffect(() => {
    getPhonesFromAPI();
  }, [locationId]);

  useEffect(() => {
    setCounter(data.length);
  }, [data]);

  useEffect(() => {
    handlePages();
    onChange(currentPage);
  }, [counter, elsPerPage]);

  useEffect(() => {
    buildSelectOptions();
  }, [columns]);

  const getPhonesFromAPI = async () => {
    try {
      const response = await buybackAPI.buybackInventory(locationId);
      const { brandsCount, inventory } = response.data.data;
      setData(inventory);
      setStateData(inventory);
      setBrandsCount(brandsCount);
    } catch (error) { }
  };

  const deletePhones = async () => {
    try {
      const response = await buybackAPI.buybackInventoryDelete(selectedRows);
      getPhonesFromAPI();
    } catch (error) { }
  };

  const buildSelectOptions = () => {
    let keys: string[] = [];

    columns.map((column) => {
      if (column.key !== "operation") {
        keys.push(buildOptionString(column.title));
      }
    });

    setSelectOptions(keys);
  };

  const handlePages = () => {
    let nrOfPages = Math.ceil(counter / elsPerPage);
    let pagesArray: number[] = [];
    for (let i = 1; i <= nrOfPages; i++) {
      pagesArray.push(i);
    }
    setPages(pagesArray);
  };

  const onChange = (pageNumber: string | number) => {
    setStateData(
      data.slice(
        (Number(pageNumber) - 1) * elsPerPage,
        Number(pageNumber) * elsPerPage
      )
    );

    setCurrentPage(Number(pageNumber));
  };

  const handleElsPerPage = (e: any) => {
    if (e < counter) {
      setElsPerPage(e);
    } else {
      setElsPerPage(counter);
    }
  };

  const handleSearch = (e: any) => {
    const filteredData = data.filter((el: any) => {
      if (el[searchBy]) {
        if (
          el[searchBy]
            .toString()
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          return el;
        }
      }
    });

    setStateData(
      filteredData.slice(
        (Number(currentPage) - 1) * elsPerPage,
        Number(currentPage) * elsPerPage
      )
    );
  };

  const handleSelect = (val: string) => {
    const valKey =
      Object.keys(ColumnsEnum)[
      Object.values(ColumnsEnum).indexOf(val as unknown as ColumnsEnum)
      ];
    valKey && setSearchBy(valKey);
  };

  const firstLetterToLowerCase = (val: string) => {
    if (val) {
      val = val.replace(" ", "_");
      val = val.charAt(0).toLowerCase() + val.slice(1);
      return val;
    }
  };

  const columnsAdmin = [
    {
      title: t("general.ID"),
      dataIndex: "id",
      key: "ID Flip",
      width: 150,
    },
    {
      title: t("general.model"),
      dataIndex: "name",
      key: "name",
      width: 150,
    },
    {
      title: t("general.memory"),
      dataIndex: "storage",
      key: "storage",
      width: 150,
    },
    {
      title: t("general.IMEI"),
      dataIndex: "imei_code",
      key: "imei_code",
      width: 150,
    },
    {
      title: t("general.defects"),
      dataIndex: "defects",
      key: "defects",
      width: 150,
    },
    {
      title: t("reception-date"),
      dataIndex: "order_date",
      key: "order_date",
      width: 150,
      render: (order_date: string) => (
        <p>
          {new Date(order_date).getDate() +
            "/" +
            (new Date(order_date).getMonth() + 1) +
            "/" +
            new Date(order_date).getFullYear()}
        </p>
      ),
    },
    {
      title: t("buyback-offer"),
      dataIndex: "offer",
      key: "offer",
      width: 150,
      render: (offer: string) => <p>{Number(offer).toFixed(2)}</p>,
    },
    {
      title: t("id-document"),
      key: "operation",
      width: 200,
      render: (text: any, record: any, index: any) => (
        <div className="d-flex align-items-center">
          <span
            className="icon start"
            dangerouslySetInnerHTML={{ __html: downloadIcon }}
          ></span>

          <a
            className="ml-2"
            onClick={(e) => {
              generatePhoneSheet(
                record.id,
                `${record.name}, ${record.storage}`,
                record.imei_code,
                record.defects,
                t
              );
            }}
          >
            {t("download-document")}
          </a>
        </div>
      ),
    },
    {
      title: t("sell-contract"),
      key: "operation",
      width: 200,
      render: (text: any, record: any, index: any) => (
        <div className="d-flex align-items-center">
          <span
            className="icon start"
            dangerouslySetInnerHTML={{ __html: downloadIcon }}
          ></span>

          <a
            className="ml-2"
            onClick={() => downloadFile(`contract_${record.id}.pdf`, "contract")}
          >
            {t("download-document")}
          </a>
        </div>
      ),
    },
  ];

  const columnsSuperAdmin = [
    {
      title: t("general.ID"),
      dataIndex: "id",
      key: "ID Flip",
      width: 150,
    },
    {
      title: t("general.store-name"),
      dataIndex: "location",
      key: "location",
      width: 150,
    },
    {
      title: t("general.model"),
      dataIndex: "name",
      key: "name",
      width: 150,
    },
    {
      title: t("general.memory"),
      dataIndex: "storage",
      key: "storage",
      width: 150,
    },
    {
      title: t("general.IMEI"),
      dataIndex: "imei_code",
      key: "imei_code",
      width: 150,
    },
    {
      title: t("general.defects"),
      dataIndex: "defects",
      key: "defects",
      width: 150,
    },
    {
      title: t("reception-date"),
      dataIndex: "order_date",
      key: "order_date",
      width: 150,
      render: (order_date: string) => (
        <p>
          {new Date(order_date).getDate() +
            "/" +
            (new Date(order_date).getMonth() + 1) +
            "/" +
            new Date(order_date).getFullYear()}
        </p>
      ),
    },
    {
      title: t("buyback-offer"),
      dataIndex: "offer",
      key: "offer",
      width: 150,
      render: (offer: string) => <p>{Number(offer).toFixed(2)}</p>,
    },
    {
      title: t("id-document"),
      key: "operation",
      width: 200,
      render: (text: any, record: any, index: any) => (
        <div className="d-flex align-items-center">
          <span
            className="icon start"
            dangerouslySetInnerHTML={{ __html: downloadIcon }}
          ></span>

          <a
            className="ml-2"
            onClick={(e) => {
              console.log(record.defects)
              generatePhoneSheet(
                record.id,
                `${record.name}, ${record.storage}`,
                record.imei_code,
                record.defects,
                t
              );
            }}
          >
            {t("download-document")}
          </a>
        </div>
      ),
    },
    {
      title: t("sell-contract"),
      key: "operation",
      width: 200,
      render: (text: any, record: any, index: any) => (
        <div className="d-flex align-items-center">
          <span
            className="icon start"
            dangerouslySetInnerHTML={{ __html: downloadIcon }}
          ></span>

          <a
            className="ml-2"
            onClick={() => downloadFile(`contract_${record.id}.pdf`, "contract")}
          >
            {t("download-document")}
          </a>
        </div>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRowsTable: any) => {
      setSelectedRows(selectedRowKeys);
    },
    getCheckboxProps: (record: any) => ({
      name: record.name,
    }),
  };

  return (
    <>
      <Menu></Menu>
      <div className="page-content">
        <Navbar
          title={t("in-house")}
          subtitle={t("menu.phones-in-house")}
          showCard={true}
          showGoBackButton={false}
          showCancelButton={false}
        ></Navbar>
        <motion.div
          initial="hidden"
          animate="enter"
          exit="exit"
          variants={topToBottomSlideIn}
          className="content d-flex"
        >
          <div className="flex-1 flow-container w-100 m-5">
            <Search
              brandsCount={brandsCount}
              selectDefaultValue="ID Flip"
              selectOptions={selectOptions}
              onChange={handleSearch}
              handleSelect={handleSelect}
            >
              {
                role === roleSuperAdmin || role === roleAdmin && (
                  <Button
                    className="ml-4"
                    variant="primary"
                    size="small"
                    onClick={() => donwloadExcel(locationId)}
                  >
                    {t("export-csv")}
                  </Button>
                ) || null
              }
              {role === roleSuperAdmin && (
                <Button
                  className="ml-4"
                  size="small"
                  variant={selectedRows.length === 0 ? "disabled" : "primary"}
                  disabled={selectedRows.length === 0 ? true : false}
                  onClick={() => {
                    setIsPopupOpen(true);
                  }}
                >
                  {t("delete-order")}
                </Button>
              )}
            </Search>

            <TableComponent
              data={stateData}
              columns={columns}
              currentPage={currentPage}
              total={data.length}
              pageSize={elsPerPage}
              pages={pages}
              handleElementsPerPage={handleElsPerPage}
              handlePage={onChange}
              rowSelection={role === roleSuperAdmin && rowSelection}
              rowKey="id"
            />
          </div>
        </motion.div>
      </div>

      {isPopupOpen && (
        <Popup
          icon="/images/folder2.png"
          headerText={t("header-confirm-deletion")}
          description={t("confirm-deletion", { products: selectedRows.length === 1 ? `${selectedRows.length} ` + t("product") : `${selectedRows.length}` + t("products") })}

        >
          <div className="d-flex justify-content-between mt-5">
            <Button
              variant="error"
              size="large"
              className="flex-48"
              onClick={() => setIsPopupOpen(false)}
            >
              {t("general.no")}
            </Button>
            <Button
              variant="primary"
              size="large"
              className="flex-48"
              onClick={() => {
                deletePhones();
                setIsPopupOpen(false);
              }}
            >
              {t("general.yes")}
            </Button>
          </div>
        </Popup>
      )}
    </>
  );
};

export default loginRedirect(InManagementPhones);
