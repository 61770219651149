import React, { forwardRef } from "react";

export type TagVariant = "yellow" | "blue" | "green";

type TagProps = {
  id?: string;
  status: string;
  variant: TagVariant | string;
};

function TagWithRef(props: TagProps, ref: any) {
  const { id, status, variant } = props;

  return <span className={`tag tag-${variant}`}>{status}</span>;
}

const Tag = forwardRef(TagWithRef);

export default Tag as typeof TagWithRef;
