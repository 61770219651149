import { closeIcon, eyeIcon } from "icons/Icons";
import React, { forwardRef, useEffect, useState } from "react";

export type InputVariant =
  | "outlined"
  | "focused"
  | "search"
  | "checked"
  | "checkbox"
  | "radio"
  | "file"
  | "password"
  | "error"
  | "valid";

export type InputType =
  | "text"
  | "password"
  | "checkbox"
  | "radio"
  | "email"
  | "file"
  | "number";

export type InputImperativeRef = React.MutableRefObject<
  | {
      toggleWaiting?: (mode?: boolean) => void;
    }
  | null
  | undefined
>;

type InputProps = {
  id?: string;
  ref?: any;
  name?: string;
  children?: React.ReactNode | React.ReactNode[];
  variant?: InputVariant;
  type?: InputType;
  placeholder?: string;
  checked?: boolean;
  defaultValue?: string;
  startLabel?: string;
  endLabel?: string;
  bottomLabel?: string;
  className?: string;
  classNameLabelContainer?: string;
  classNameStartLabel?: string;
  classNameEndLabel?: string;
  classNameContainer?: string;
  classNameBottomIcon?: string;
  classNameStartIcon?: string;
  classNameBottomLabel?: string;
  size?: "small" | "medium" | "large";
  startIcon?: string;
  endIcon?: string;
  startLabelIcon?: string;
  endLabelIcon?: string;
  labelIcon?: string;
  bottomIcon?: string;
  value?: string;
  iRef?: InputImperativeRef;
  register?: any;
  selectedFiles?: any[];
  onClick?: any;
  onChange?: any;
  onBlur?: any;
};

function InputWithRef(props: InputProps, ref: any) {
  const {
    id,
    name,
    variant,
    className: classNameProp,
    classNameLabelContainer,
    classNameStartLabel,
    classNameEndLabel,
    classNameStartIcon,
    classNameContainer,
    classNameBottomIcon,
    classNameBottomLabel,
    type,
    placeholder,
    defaultValue,
    checked,
    startLabel,
    endLabel,
    bottomLabel,
    children,
    size,
    startIcon,
    endIcon,
    startLabelIcon,
    endLabelIcon,
    bottomIcon,
    onBlur: onBlurProp,
    labelIcon,
    onClick: onClickProp,
    onChange: onChangeProp,
    iRef,
    selectedFiles,
    value,
    ...rest
  } = props;

  const onBlur = (e: any) => {
    if (onBlurProp) {
      onBlurProp(e);
    }
  };

  const onChange = (e: any) => {
    if (onChangeProp) {
      onChangeProp(e);
    }
  };

  const onClick = () => {
    if (onClickProp) {
      onClickProp();
    }
  };

  return (
    <div
      className={`${(classNameContainer && classNameContainer) || ""}`}
      {...rest}
    >
      <div
        className={`${
          (classNameLabelContainer && classNameLabelContainer) || ""
        }`}
      >
        <label
          htmlFor={id}
          className={`label ${
            (classNameStartLabel && classNameStartLabel) || ""
          }`}
        >
          {startLabel && (
            <div dangerouslySetInnerHTML={{ __html: startLabel }}></div>
          )}
          {/* {startLabel} */}
          {startLabelIcon ? (
            <span
              className="d-flex align-items-center ml-2"
              dangerouslySetInnerHTML={{ __html: startLabelIcon }}
            ></span>
          ) : null}{" "}
        </label>

        {selectedFiles?.length === 0 && (
          <label
            htmlFor={id}
            className={`label ${
              (classNameEndLabel && classNameEndLabel) || ""
            }`}
          >
            {endLabelIcon ? (
              <span
                className="d-flex align-items-center ml-2 mr-2"
                dangerouslySetInnerHTML={{ __html: endLabelIcon }}
              ></span>
            ) : null}
            {endLabel}
          </label>
        )}
      </div>
      {!selectedFiles || selectedFiles.length === 0 ? (
        <>
          <div className={`${(classNameProp && classNameProp) || ""}`}>
            {startIcon ? (
              <span
                className="icon-input start"
                dangerouslySetInnerHTML={{ __html: startIcon }}
              ></span>
            ) : null}
            {variant === "checkbox" ? (
              <label
                htmlFor={id}
                className={`label ${
                  (classNameStartLabel && classNameStartLabel) || ""
                }`}
              >
                <input
                  id={id}
                  className={`input ${(size && `input-${size}`) || ""} ${
                    (variant && `input-${variant}`) || ""
                  } ${(classNameStartIcon && `${classNameStartIcon}`) || ""} `}
                  type={type}
                  name={name}
                  placeholder={placeholder}
                  defaultValue={defaultValue}
                  ref={ref}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  checked={checked}
                  multiple
                />
                <span className="checkmark"></span>
              </label>
            ) : (
              <input
                id={id}
                className={`input ${(size && `input-${size}`) || ""} ${
                  (variant && `input-${variant}`) || ""
                } ${(classNameStartIcon && `${classNameStartIcon}`) || ""} `}
                type={type}
                name={name}
                placeholder={placeholder}
                defaultValue={defaultValue}
                ref={ref}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                checked={checked}
                multiple
              />
            )}
            {endIcon ? (
              <span
                onClick={onClick}
                className="icon-input end"
                dangerouslySetInnerHTML={{ __html: endIcon }}
              ></span>
            ) : null}
          </div>
          <div className="d-flex flex-row mt-2">
            {bottomIcon ? (
              <span
                className={classNameBottomIcon}
                dangerouslySetInnerHTML={{ __html: bottomIcon }}
              ></span>
            ) : null}
            <div className={classNameBottomLabel}>{bottomLabel}</div>
          </div>
        </>
      ) : (
        <div className="d-flex flex-wrap justify-content-start bg-grey file-container">
          {selectedFiles &&
            Array.from(selectedFiles).map((sf: any, index) => (
              <div
                key={index}
                className="d-flex align-items-center bg-secondary file"
              >
                <span
                  onClick={() => onClickProp(sf)}
                  className="d-flex mr-2"
                  dangerouslySetInnerHTML={{ __html: closeIcon }}
                ></span>
                <p className="text-white no-select">{sf.name}</p>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

const Input = forwardRef(InputWithRef);

export default Input as typeof InputWithRef;
