import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { motion } from "framer-motion";

import Menu from "components/Menu";
import Navbar from "components/Navbar";
import Tag from "components/organisms/Tag";
import Search from "components/organisms/Search";
import Dropdown from "components/organisms/Dropdown";
import TableComponent from "components/organisms/Table";

import { downloadIcon } from "icons/Icons";
import { buybackAPI } from "api/buybackAPI";
import { loginRedirect } from "hoc/LoginRedirect";
import { topToBottomSlideIn } from "utils/animations";
import { buildOptionString } from "utils/functions";
import { downloadFile } from "utils/api";
import { useTranslation } from "react-i18next";

const SentPhones = () => {
  enum ColumnsEnum {
    sell_order_id = "ID Flip",
    model = "Model",
    storage = "Memorie",
    order_date = "Data receptie",
    shipment_date = "Data trimitere",
    offer = "Oferta",
    imei_code = "IMEI",
    defects = "Defecte",
    tracking_number = "AWB",
    shipment_status = "Status",
    count = "Numar telefoane",
    transport_document_id = "Fisa Transport",
  }

  const { t } = useTranslation();

  const columnsAWBUnic = [
    {
      title: t("transport-sheet"),
      dataIndex: "transport_document_id",
      key: "transport_document_id",
      width: 150,
    },
    {
      title: t("date-sent"),
      dataIndex: "shipment_date",
      key: "shipment_date",
      width: 150,
      render: (shipment_date: string) => (
        <p>
          {new Date(shipment_date).getDate() +
            "/" +
            (new Date(shipment_date).getMonth() + 1) +
            "/" +
            new Date(shipment_date).getFullYear()}
        </p>
      ),
    },
    {
      title: t("count-phones"),
      dataIndex: "count",
      key: "count",
      width: 150,
    },
    {
      title: t("AWB"),
      dataIndex: "tracking_number",
      key: "tracking_number",
      width: 150,
      render: (awb: any) => {
        return (
          <p
            onClick={(e) => {
              downloadFile(`${awb}.pdf`, "awb");
            }}
            className="text-underline cursor-pointer"
          >
            {awb}
          </p>
        );
      },
    },
    {
      title: t("status"),
      key: "status",
      dataIndex: "shipment_status",
      width: 150,
      render: (tag: any) => {
        let variant = "";
        switch (tag) {
          case "AWB_CREAT":
            variant = "yellow";
            break;
          case "IN_CURS_DE_LIVRARE":
            variant = "blue";
            break;
          case "LIVRAT":
            variant = "green";
            break;
          default:
            break;
        }
        return <Tag variant={variant} status={t(tag)} />;
      },
    },
    {
      title: t("download-sheet"),
      key: "operation",
      width: 200,
      render: (text: any, record: any, index: any) => (
        <div
          className="d-flex align-items-center"
          onClick={(e) => {
            downloadFile(`${record.filename}`, "trdoc");
          }}
        >
          <span
            className="icon start"
            dangerouslySetInnerHTML={{ __html: downloadIcon }}
          ></span>

          <a className="ml-2">{t("download-sheet")}</a>
        </div>
      ),
    },
  ];

  const columnsAWBProdus = [
    {
      title: t("general.ID"),
      dataIndex: "sell_order_id",
      key: "sell_order_id",
      width: 150,
    },
    {
      title: t("general.model"),
      dataIndex: "model",
      key: "model",
      width: 150,
    },
    {
      title: t("general.memory"),
      dataIndex: "storage",
      key: "storage",
      width: 150,
    },
    {
      title: t("general.IMEI"),
      dataIndex: "imei_code",
      key: "imei_code",
      width: 150,
    },
    {
      title: t("date-sent"),
      dataIndex: "shipment_date",
      key: "shipment_date",
      width: 150,
      render: (shipment_date: string) => (
        <p>
          {new Date(shipment_date).getDate() +
            "/" +
            (new Date(shipment_date).getMonth() + 1) +
            "/" +
            new Date(shipment_date).getFullYear()}
        </p>
      ),
    },
    {
      title: t("AWB"),
      dataIndex: "tracking_number",
      key: "tracking_number",
      width: 150,
      render: (awb: any) => {
        return (
          <p
            onClick={(e) => {
              downloadFile(`${awb}.pdf`, "awb");
            }}
            className="text-underline cursor-pointer"
          >
            {awb}
          </p>
        );
      },
    },
    {
      title: t("status"),
      key: "status",
      dataIndex: "shipment_status",
      width: 150,
      render: (tag: any) => {
        let variant = "";
        switch (tag) {
          case "AWB_CREAT":
            variant = "yellow";
            break;
          case "IN_CURS_DE_LIVRARE":
            variant = "blue";
            break;
          case "LIVRAT":
            variant = "green";
            break;
          default:
            break;
        }
        return <Tag variant={variant} status={t(tag)} />;
      },
    },
    {
      title: t("download-sheet"),
      key: "operation",
      width: 200,
      render: (text: any, record: any, index: any) => (
        <div
          className="d-flex align-items-center"
          onClick={(e) => {
            downloadFile(`${record.filename}`, "trdoc");
          }}
        >
          <span
            className="icon start"
            dangerouslySetInnerHTML={{ __html: downloadIcon }}
          ></span>

          <a className="ml-2">{t("download-sheet")}</a>
        </div>
      ),
    },
    {
      title: t("sell-contract"),
      key: "operation",
      width: 200,
      render: (text: any, record: any, index: any) => (
        <div className="d-flex align-items-center">
          <span
            className="icon start"
            dangerouslySetInnerHTML={{ __html: downloadIcon }}
          ></span>

          <a
            className="ml-2"
            onClick={() => downloadFile(`contract_${record.sell_order_id}.pdf`, "contract")}
          >
            {t("download-document")}
          </a>
        </div>
      ),
    },
  ];

  const { locationId } = useSelector((state: any) => state.location);

  const [data, setData] = useState<any[]>([]);
  const [stateData, setStateData] = useState<any[]>([]);
  const [rowKey, setRowKey] = useState<string>("transport_document_id");
  const [columns, setColumns] = useState<any[]>(columnsAWBProdus);
  const [elsPerPage, setElsPerPage] = useState(10);
  const [counter, setCounter] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState<number[]>([]);
  const [selectOptions, setSelectOptions] = useState<string[]>([]);
  const [selectedOption, setSelectedOption] =
    useState<string>(t("awb.product"));
  const [defaultSelectOption, setDefaultSelectOption] = useState<string>("");
  const [searchBy, setSearchBy] = useState<any>("sell_order_id");

  useEffect(() => {
    buildSelectOptions();
  }, [columns]);

  useEffect(() => {
    if (selectedOption === t("awb.unique")) {
      getTransportSheetsFromAPI();
      setColumns(columnsAWBUnic);
      setRowKey("transport_document_id");
    } else {
      getTransportSheetsFromAPIGroupedBy();
      setColumns(columnsAWBProdus);
      setRowKey("sell_order_id");
    }
  }, [selectedOption, locationId]);

  useEffect(() => {
    setCounter(data.length);
  }, [data]);

  useEffect(() => {
    handlePages();
    onChange(currentPage);
  }, [counter, elsPerPage]);

  const getTransportSheetsFromAPI = async () => {
    try {
      setData([]);
      const response = await buybackAPI.buybackShipped(locationId);
      const { shipments } = response.data;
      setData(shipments);
      setStateData(shipments);
    } catch (error) { }
  };

  const getTransportSheetsFromAPIGroupedBy = async () => {
    try {
      setData([]);
      const response = await buybackAPI.buybackShipped(
        locationId,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        "false"
      );
      const { shipments } = response.data;
      setData(shipments);
      setStateData(shipments);
    } catch (error) { }
  };

  const buildSelectOptions = () => {
    let keys: string[] = [];

    columns.map((column) => {
      if (column.key !== "operation") {
        keys.push(buildOptionString(column.title));
      }
    });

    setSelectOptions(keys);
    setDefaultSelectOption(keys[0]);
  };

  const handlePages = () => {
    let nrOfPages = Math.ceil(counter / elsPerPage);
    let pagesArray: number[] = [];
    for (let i = 1; i <= nrOfPages; i++) {
      pagesArray.push(i);
    }
    setPages(pagesArray);
  };

  const onChange = (pageNumber: string | number) => {
    setStateData(
      data.slice(
        (Number(pageNumber) - 1) * elsPerPage,
        Number(pageNumber) * elsPerPage
      )
    );

    setCurrentPage(Number(pageNumber));
  };

  const handleElsPerPage = (e: any) => {
    if (e < counter) {
      setElsPerPage(e);
    } else {
      setElsPerPage(counter);
    }
  };

  const handleSearch = (e: any) => {
    const filteredData = data.filter((el: any) => {
      if (el[searchBy]) {
        if (
          el[searchBy]
            .toString()
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          return el;
        }
      }
    });
    setStateData(
      filteredData.slice(
        (Number(currentPage) - 1) * elsPerPage,
        Number(currentPage) * elsPerPage
      )
    );
  };

  const handleSelect = (val: any) => {
    const valKey =
      Object.keys(ColumnsEnum)[
      Object.values(ColumnsEnum).indexOf(val as unknown as ColumnsEnum)
      ];
    valKey && setSearchBy(valKey);
  };

  const firstLetterToLowerCase = (val: string) => {
    if (val) {
      val = val.replace(" ", "_");
      val = val.charAt(0).toLowerCase() + val.slice(1);
      return val;
    }
  };

  return (
    <>
      <Menu></Menu>
      <div className="page-content">
        <Navbar
          title={t("in-house")}
          subtitle={t("menu.sent-phones")}
          showCard={true}
          showGoBackButton={false}
          showCancelButton={false}
        ></Navbar>
        <motion.div
          initial="hidden"
          animate="enter"
          exit="exit"
          variants={topToBottomSlideIn}
          className="content d-flex"
        >
          <div className="flex-1 flow-container w-100 m-5">
            <Search
              onChange={handleSearch}
              handleSelect={handleSelect}
              selectOptions={selectOptions}
              selectDefaultValue={defaultSelectOption}
            >
              <Dropdown
                options={[t("awb.product"), t("awb.unique")]}
                setSelectedItemProp={setSelectedOption}
              />
            </Search>

            <TableComponent
              pages={pages}
              rowKey={rowKey}
              data={stateData}
              columns={columns}
              total={data.length}
              pageSize={elsPerPage}
              handlePage={onChange}
              currentPage={currentPage}
              handleElementsPerPage={handleElsPerPage}
            />
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default loginRedirect(SentPhones);
